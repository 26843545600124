.message-entry {
    @include white-box();

    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 1.4rem;
    padding: 2.8rem 3.3rem;
    text-decoration: none;

    &.-large {
        max-width: 83rem;
        margin-bottom: 2.4rem;
    }

    &:last-of-type {
        margin-bottom: 2.5rem;
    }

    &.-personalia { // this is a theme
        padding: 2rem 3.3rem 3.3rem;

        &::before {
            border: .1rem solid var(--color-blue--lighter);
            box-shadow: 0 .2rem .4rem 0 rgba(var(--color-black), .1);
            border-radius: .4rem;
        }

        &::after {
            border: .1rem solid var(--color-blue--lighter);
            box-shadow: 0 .2rem .4rem 0 rgba(var(--color-black), .1);
        }
    }

    // this is used when a link is available
    &.-animated {
        @extend .-animated;

        &:hover,
        &:focus {
            .message-entry__link {
                opacity: 1;
                transform: translate3d(1.1rem, 0, 0);
            }
        }
    }
}

.message-entry__link {
    transition: opacity .3s ease, transform .3s ease;
    opacity: 0;
    transform: translate3d(0, 0, 0);

    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.7rem;
    height: 4.7rem;
    background: var(--color-blue);
    border-radius: 100%;
    color: var(--color-white);

    // used after because the white-box() mixin interferes with the &::before transform property
    // this was easier than fixing white-box() mixin
    &::after {
        content: '';
        transition: background .3s ease;

        display: block;
        width: 1rem;
        height: 1.6rem;

        background: currentColor;
        mask-image: url('../Images/svg/icon-arrow.svg');
        mask-repeat: no-repeat;
        mask-size: 1rem 1.6rem;
    };

    @media (--query-extra-large) {
        margin-left: auto;
    };
}

.message-entry__inner {
    transition: transform .3s ease;
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    flex-shrink: 1;

    .-large & {
        padding: 2rem 1.7rem;
    }
}

.message-entry__date,
.message-entry__text{
    display: inline-block;
    vertical-align: middle;
    margin: 0 1rem 0 0;
    font-family: var(--font-roboto);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--smaller);
    color: var(--color-blue--light);

    &.-uppercase {
        text-transform: uppercase;
    }
}

.message-entry__group {
    margin: 0 0 0 2rem;
    color: var(--color-blue--light);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--smaller);
    font-family: var(--font-roboto);
    text-transform: uppercase;
}

.message-entry__icon {
    //@extend .tag__icon;
    display: none;

    .-personalia & {
        display: inline-block;
        margin-right: .6rem;
    }
}

.message-entry__title {
    display: inline-block;
    width: 100%;
    margin-top: .5rem;
    margin-bottom: 0;
    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--larger);
    line-height: 1.5;
    color: var(--color-blue--dark);

    .-large & {
        font-weight: var(--font-weight--regular);
    }

    .-personalia & { // this is a theme
        margin-top: 1.2rem;
    }
}

.message-entry__description {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--small);
    line-height: 1.5;
    color: var(--color-blue);

    .-large & {
        margin-bottom: 2.5rem;
        color: var(--color-blue--light);
    }

    @media (--screen-medium) {
        margin-top: 1.9rem;
    }
}

.message-entry__button {
    @extend .button;
    @extend .-white;

    display: block;
    margin-bottom: 3.4rem;
}

.message-entry__seen {
    position: absolute;
    top: 2.2rem;
    right: 1.3rem;
    display: none;

    .message-overview-holder & {
        display: inline-block;
        overflow: hidden;

        &:hover {
            overflow: visible;

            .message-entry__seen-tooltip {
                opacity: 1;
            }
        }
    }
}

.message-entry__seen-tooltip {
    @include tooltip();
    position: absolute;
    top: calc(100% + 1rem);
    left: 50%;
    display: inline-block;
    width: auto;
    min-width: 13.8rem;
    font-size: var(--font-size--extra-small);
    text-transform: uppercase;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity .3s ease;

    &::before {
        position: absolute;
        top: -.9rem;
        left: 50%;
        width: 0;
        height: 0;
        border-color: transparent transparent var(--color-blue--light) transparent;
        border-style: solid;
        border-width: 0 .5rem .9rem;
        transform: translateX(-50%);
        content: '';
    }
}

.message-entry__seen-image {
    display: block;
    width: 1.8rem;
    height: 1.2rem;
}
