main {
    display: flex;
    flex: 1 0 auto; // used for sticky footer using flex-box
    flex-direction: column;

    .-page-home & {
        z-index: 1;
    }

    .-page-offices-overview &,
    .-page-intranet-specialists-overview &,
    .-page-intranet-agenda-detail & {
        padding-top: 13.5rem;

        @media (--screen-medium) {
            padding-top: 8.5rem;
        }
    }
}
