.form__legend {
    transition: all .2s ease;

    display: block;
    margin-bottom: 2.5rem;
    padding: 0;

    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--regular);
    font-size: 1.8rem;
    line-height: 1.26;
    color: var(--color-black-2);
}
