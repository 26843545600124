.forum-post {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    max-width: 106.5rem;
    margin: 0 auto 2.4rem auto;
    padding: 1.8rem 3rem;
    text-decoration: none;

    background: var(--color-white);
    box-shadow: 0 .2rem .4rem 0 rgba(var(--rgb-black), .1);

    @media (--query-medium-extra-large) {
        padding: 4rem 6rem;
    }

    @media (--query-large-extra-large) {
        flex-wrap: nowrap;
    }
}

.forum-post__title {
    display: block;
    width: 100%;
    max-width: 66.9rem;
    margin: 0 5.4rem 2rem 0;

    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;

    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--bold);
    font-size: 2rem;
    line-height: (30/20);
    color: var(--color-blue--dark);

    @media (--query-medium-extra-large) {
        min-width: 30rem;
    }

    @media (--query-large-extra-large) {
        margin-bottom: 0;
    }
}

.forum-post__aside {
    display: flex;
    align-items: center;
}

.forum-post__meta {
    display: flex;
    flex-direction: column;
}

.forum-post__img {
    display: block;
    margin: 0 5.4rem 0 0;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 100%;
}

.forum-post__text {
    display: block;
    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: 1.5rem;
    line-height: (18/15);
    color: var(--color-blue-2);

    &.-top {
        font-weight: bold;
    }

    &.-reaction {
        font-family: var(--font-quicksand);
        font-weight: var(--font-weight--medium);
        font-size: 1.8rem;
        line-height: (30/18);

        &::before {
            $w: 2rem;
            $h: 2rem;
            $icon: 'speech-bubble.svg';

            content: '';
            transition: background .3s ease;

            display: inline-block;
            vertical-align: middle;
            width: $w;
            height: $h;
            margin-right: .9rem;

            background: url($icon) center center no-repeat;
            background-size: $w $h;

            @supports (mask-repeat: no-repeat) {
                background: currentColor;
                mask-image: url($icon);
                mask-repeat: no-repeat;
                mask-size: $w $h;
            }
        }
    }
}
