.header {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 1.6rem;

    &.-search {
        background: linear-gradient(to right, rgba(96, 168, 255, .2) 0%, rgba(48, 35, 174, .2) 100%);
    }

    > * {
        z-index: 1;
    }
}

.header__image {
    display: none;

    .-page-home & {
        position: absolute;
        top: -110%;
        right: -41%;
        z-index: 0;
        display: block;
        height: 69rem;

        @media (--screen-medium) {
            top: -29.3rem;
            right: -23rem;
            width: auto;
            height: auto;
        }
    }
}

.header__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    @media (--query-mini-medium) {
        padding-top: 4.4rem;
    }
}

.header__buttons {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: flex-end;
    justify-self: flex-end;
    padding: 2.7rem 0;
}

.header__logo {
    display: none;

    @media (--screen-medium) {
        display: block;
        justify-self: flex-start;
        width: auto;
    }
}

.header__title {
    width: 100%;
    margin: 0;
    color: var(--color-blue);
    font-weight: var(--font-weight--regular);
    text-align: center;
}
