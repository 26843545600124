.meta-block {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: calc(83rem + (2rem * 2)); // margin left and right of children
    margin: 0 auto 3rem;
    align-items: center;
    justify-content: center;
}

.meta-block__item {
    display: flex;
    align-items: center;
    margin: 0 1.5rem 2rem;

    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: 1.5rem;
    line-height: 1.2;
    color: var(--color-blue--light);
    text-decoration: none;


    &:hover,
    &:focus {
        text-decoration: underline;
    }

    &::before {
        content: '';
        transition: background .3s ease;
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 1rem;
        background: currentColor;
        mask-repeat: no-repeat;
    }

    &.-members {
        &::before {
            mask-image: url('../Images/svg/icon-members.svg');
            mask-size: 1.4rem 1.4rem;
        }
    }

    &.-mail {
        &::before {
            width: 1.2rem;
            height: 1rem;
            mask-image: url('../Images/svg/icon-mail.svg');
            mask-size: 1.2rem 1rem;
        }
    }

    &.-pin {
        &::before {
            width: .9rem;
            height: 1.4rem;
            mask-image: url('../Images/svg/icon-pin.svg');
            mask-size: .9rem 1.4rem;
        }
    }

    &.-phone {
        &::before {
            mask-image: url('../Images/svg/icon-phone.svg');
            mask-size: 1.4rem 1.4rem;
        }
    }

    &.-tag {
        border-radius: 1.1rem;
        padding: .2rem 1.9rem;
        background: var(--color-blue);
        color: var(--color-white);
        text-decoration: none;

        &::before {
            display: none;
        }
    }
}
