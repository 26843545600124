.mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    display: none;
    width: 100%;
    height: 100%;
    padding: 2rem;

    overflow-y: auto;
    background-color: var(--color-blue);

    transform: translateY(-100%);
    transition: transform .3s ease;

    .-mobile-menu-open & {
        transform: translateY(0);

        &.-secondary-menu {
            display: none;
        }
    }

    .-secondary-mobile-menu-open & {
        &.-secondary-menu {
            transform: translateY(0);
        }

        &.-primary-menu {
            display: none;
        }
    }

    @media (--query-mini-medium) {
        display: flex;
        flex-direction: column;
    }
}

.mobile-menu-overlay__head {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 2rem;
}

.mobile-menu-overlay__bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 1.2rem 0 5rem;

    @media (--screen-medium) {
        display: none;
    }
}

.mobile-menu-overlay__logo {
    width: 4.4rem;
    height: 4.4rem;
}

.mobile-menu-overlay__tools {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.mobile-menu-overlay__hamburger {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 4.4rem;
    height: 4.4rem;
    background-color: var(--color-white);
    border-radius: 50%;
    cursor: pointer;

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1.8rem;
        height: .2rem;
        background-color: var(--color-blue);
        border-radius: 5rem;
        transform-origin: center;

        &:first-child {
            transform: translate3d(-50%, -50%, 0) rotateZ(45deg);
        }

        &:last-child {
            transform: translate3d(-50%, -50%, 0) rotateZ(-45deg);
        }
    }
}

.mobile-menu-overlay__title {
    display: block;
    margin-bottom: 2rem;
    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--regular);
    font-size: 2rem;
    color: var(--color-white);

    &:first-letter {
        text-transform: uppercase;
    }
}
