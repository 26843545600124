.flash-message {
    transition:
        opacity .3s ease,
        max-height .3s ease,
        padding-top .3s ease,
        padding-bottom .3s ease
    ;

    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 2.8rem;
    border-radius: 3.7rem;

    /*animation-name: autoHide;
    animation-duration: 1s;
    animation-delay: 15s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease;*/

    max-height: 100vh;

    &.-hidden {
        opacity: 0;
        min-height: 7.4rem; // to prevent a little jump in height (due to icon in content) when -hidden is taken off

        @media (--query-mini-medium) {
            min-height: initial;
            max-height: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &.-fixed-width {
        max-width: 57rem;
    }

    &.-type-warning {
        background: var(--color-red--light);
        color: var(--color-red);

        &::before {
            content: '';
            transition: background .3s ease;

            display: inline-block;
            width: 1.8rem;
            height: 1.8rem;
            margin-right: 1rem;

            background: url(../Images/svg/icon-circled-exclamation-mark.svg) center center no-repeat;
            background-size: 1.8rem 1.8rem;

            @supports (mask-repeat: no-repeat) {
                background: currentColor;
                mask-image: url(../Images/svg/icon-circled-exclamation-mark.svg);
                mask-repeat: no-repeat;
                mask-size: 1.8rem 1.8rem;
            }
        }
    }

    &.-type-success {
        background: var(--color-green--lighter);
        color: var(--color-green);

        &::before {
            content: '';
            transition: background .3s ease;

            display: inline-block;
            width: 1.8rem;
            height: 1.8rem;
            margin-right: 1rem;

            background: url(../Images/svg/icon-checkmark-circled-green.svg) center center no-repeat;
            background-size: 1.8rem 1.8rem;

            @supports (mask-repeat: no-repeat) {
                background: currentColor;
                mask-image: url(../Images/svg/icon-checkmark-circled-green.svg);
                mask-repeat: no-repeat;
                mask-size: 1.8rem 1.8rem;
            }
        }
    }

    .heading-block & {
        margin-bottom: 3.5rem;
    }
}

@keyframes autoHide {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        max-height: 0;
        padding: 0;
    }
}
