.menu-button {
    position: absolute;
    top: 1.6rem;
    right: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 4.4rem;
    height: 4.4rem;
    background-color: var(--color-blue--dark);
    border-radius: 50%;
    cursor: pointer;

    span {
        width: 1.8rem;
        height: .2rem;
        background-color: var(--color-white);
        border-radius: 5rem;

        &:not(:last-child) {
            margin-bottom: .3rem;
        }
    }

    @media (--query-medium-extra-large) {
        display: none;
    }

    &.-close {
        top: 0;
        right: 0;
        background: var(--color-white);

        span {
            position: absolute;
            background-color: var(--color-blue--dark);
            transform-origin: center center;
            transform: rotate(45deg);

            &:last-child {
                transform: rotate(-45deg);
            }
        }
    }

    &.-static {
        position: static;
    }
}
