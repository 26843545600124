.main-image {
    display: block;
    width: 100%;
    max-width: 83rem;
    margin: 0 auto 4rem;
    text-align: center;

    &.-overlapping {
        position: relative;
        z-index: 1;
        margin-top: -5rem;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: .4rem;
    }
}
