
@import '~@inventis/cookieconsent/src/cookie-consent';
@import '~dialog-polyfill/dialog-polyfill.css';

/*
    Dependency overwrites
*/
.h-hidden {
    display: none!important;
}

.cookie-consent-component__title {
    color: var(--color-blue);
}

.cookie-consent__options {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--color-gray--dark);
    margin-top: 3rem;
    padding: 0;
    transition: height 1s;

    &:before {
        content: "";
        border-style: solid;
        border-width: 0 1.5rem 2rem 1.5rem;
        border-color: transparent transparent var(--color-gray--dark) transparent;
        position: absolute;
        left: 4rem;
        top: -2rem;
    }
}

.cookie-consent__options-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--color-blue);
    text-decoration: underline;
    img {
        margin-right: .7rem;
    }
}

.cookie-consent__buttons {
    text-align: start;
    margin-top: 3rem;
}

/*
    Custom component rules
 */
.cookie-consent-component {
    box-sizing: border-box;
    display: block;
    width: 100%;
    max-width: 61.5rem;
    background: var(--color-gray);
    border-color: var(--color-gray);
    + .backdrop,
    &::backdrop {
        background-color: rgba(0,10,18, 0.6);
    }
}

.cookie-consent-component__title {
    display: block;
    margin: 0 0 2rem 0;
    color: var(--color-blue);
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 1.25;
}

.cookie-consent-component__text {
    display: block;
    margin: 0 0 3rem 0;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.46;
    color: var(--color-black-dark);
}

.cookie-consent-component__inner {
    margin: 0;
    padding: 2rem;
}

.cookie-consent-component__option-row {
    margin: 2.5rem 2.5rem 2.5rem 5rem;

    label {
        color: var(--color-blue);
        font-weight: 500;
        cursor: pointer;

        &::before {
            content: "";
            background-color: var(--color-white);
            width: 1.8rem;
            height: 1rem;
            left: 2rem;
            position: absolute;
            border-radius: 0.2rem;
            padding-bottom: 0.8rem;
        }
    }

    input:disabled + label {
        cursor: not-allowed;
    }

    input:checked + label::before {
        content: url("../Images/svg/icon-checkmark-blue.svg");
    }

    input:disabled + label::before {
        background-color: var(--color-gray-4);
    }
}

.cookie-consent-component__category-description {
    color: var(--color-gray-4);
}

.cookie-consent-component__footer {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100vw;
    z-index: 9998;
    padding: .7rem 2rem;
    background-color: var(--color-blue);
    color: var(--color-white);
    box-shadow: 0 -0.2rem 0.4rem 0 rgba(0, 0, 0, .2);

    .spacer {
        flex-grow: 1;
    }

    a {
        color: white;
        margin-right: 2rem;
    }

    .button {
        &:hover {
            background-color: var(--color-blue--dark);
        }
    }
}

.cookie-consent-component__info-text {
    display: none;
}

@media(--screen-medium) {
    .cookie-consent-component__info-text {
        display: block;
    }
}