@mixin white-box-gradient($sidebarColor: var(--color-white), $backgroundColor: var(--color-white), $hoverBackgroundColor: var(--color-gray), $delimiter: .6rem) {
    &::before {
        background: linear-gradient(to right, #{$sidebarColor} #{$delimiter}, #{$backgroundColor} #{$delimiter});
        opacity: 1;
    }

    &::after {
        background: linear-gradient(to right, #{$sidebarColor} #{$delimiter}, #{$hoverBackgroundColor} #{$delimiter});
        opacity: 0;
    }
}

@mixin agenda-entry-gradient($sidebarColor: var(--color-white), $backgroundColor: var(--color-white)) {
    background: linear-gradient(to bottom, #{$sidebarColor} 33.33%, #{$backgroundColor} 33.33%, #{$backgroundColor} 66.66%, #{$sidebarColor} 66.66%, #{$sidebarColor} 100%);
}

@mixin tooltip() {
    padding: 1.2rem .9rem;
    color: var(--color-white);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--small);
    font-family: var(--font-roboto);
    background-color: var(--color-blue--light);
    border-radius: .7rem;
}

@mixin white-box() {
    position: relative;
    z-index: 1;
    width: 100%;
    transition: transform .3s ease;

    > * {
        transition: transform .3s ease;
    }

    &::before {
        background: var(--color-white); // default
    }

    &.-medizorg {
        @include white-box-gradient(var(--color-medizorg));
    }

    &.-corent {
        @include white-box-gradient(var(--color-corent));
    }

    &.-covent {
        @include white-box-gradient(var(--color-covent));
    }

    &.-covimmo {
        @include white-box-gradient(var(--color-covimmo));
    }

    &.-mz-consult {
        @include white-box-gradient(var(--color-mz-consult));
    }

    &.-personalia {
        @include white-box-gradient($backgroundColor: var(--color-gray--light), $hoverBackgroundColor: var(--color-blue--lighter), $delimiter: 0);
    }

    &.-algemeen {
        @include white-box-gradient(var(--color-algemeen));
    }

    &::before,
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        border-radius: .4rem;
        //box-shadow: rgba(0, 0, 0, .1) 0 0 .5rem .2rem;
        box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, .1);
        transition:
            transform .3s ease,
            opacity .3s ease,
            box-shadow .3s ease;
        content: '';
    }

    &.-animated {
        &:hover {
            &::before,
            &::after {
                transform: scaleX(1.03);
            }

            &::before {
                opacity: 0;
            }

            &::after {
                opacity: 1;
            }

            > * {
                transform: translateX(-1.1rem);

                &::before {
                    transform: translateX(-1.1rem);
                }
            }
        }
    }

    &.-animated-up {
        transition: transform .3s ease;

        &::before {
            opacity: 0;
        }

        &:hover {
            &::before,
            &::after {
                background-color: var(--color-white);
                transform: scale(1.03);
                opacity: 1;
            }

            &::before {
                box-shadow: rgba(0, 0, 0, .1) 0 0 .5rem .2rem;
            }

            &::after {
                box-shadow: rgba(0, 0, 0, .1) 0 1rem .5rem .2rem;
            }

            transform: translateY(-1.1rem);
        }
    }

    &.-animated-blue {

        &::before {
            opacity: 0;
        }

        &::after {
            box-shadow: rgba(0, 0, 0, .1) 0 0 .5rem .2rem;
        }

        &:hover {
            &::before,
            &::after {
                background-color: var(--color-gray);
                opacity: 1;
            }

            &::before {
                box-shadow: rgba(0, 0, 0, .1) 0 0 .5rem .2rem;
            }

            &::after {
                box-shadow: rgba(0, 0, 0, .1) 0 1rem .5rem .2rem;
            }

            transform: translateY(-1.1rem);
        }
    }
}

@mixin content-theme($theme: default) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a,
    th {
        color: var(--color-#{$theme});
    }

    // TODO: use theme-button mixin
    .button {
        &.-white {
            color: var(--color-#{$theme});
            border-color: var(--color-#{$theme});

            &.-dark {
                color: var(--color-white);
                background-color: var(--color-#{$theme}--dark);
                border-color: var(--color-#{$theme}--dark);
            }
        }

        &.-blue {
            color: var(--color-white);
            background-color: var(--color-#{$theme});
            border-color: var(--color-#{$theme});

            &.-dark {
                background-color: var(--color-#{$theme}--dark);
                border-color: var(--color-#{$theme}--dark);
            }
        }
    }
}

@mixin theme-button($theme: default) {
    color: var(--color-white);
    background-color: var(--color-#{$theme});
    border-color: var(--color-#{$theme});

    &:hover {
        background-color: var(--color-#{$theme}--dark);
        border-color: var(--color-#{$theme}--dark);
    }
}
