.agenda-holder {
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    margin-bottom: 7rem;

    @media (--query-medium-extra-large) {
        padding-top: 3.8rem;
    }
}

.agenda-holder__title {
    display: block;
    margin: 0 0 2rem 0;
    color: var(--color-blue--dark);
}
