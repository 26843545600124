.reply {
    display: block;
    margin: 0 0 1rem;
    padding: 2rem;
    border-radius: .4rem;
    background: var(--color-white);
    border: .2rem solid var(--color-gray--dark);

    @media (--query-medium-extra-large) {
        padding: 4rem 5rem;
        margin: 0 0 2rem;
    }
}

.reply__meta {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.reply__action {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    color: var(--color-blue--light);

    &:hover,
    &:focus {
        color: var(--color-blue);
    }

    &::before {
        content: '';
        transition: background .3s ease;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;

        @supports (mask-repeat: no-repeat) {
            mask-repeat: no-repeat;
            mask-position: center center;
        }
    }

    &.-delete::before {
        $icon: 'bin.svg';
        $w: 1.7rem;
        $h: 1.9rem;
        width: $w;
        height: $h;
        background-image: url($icon);
        background-size: $w $h;

        @supports (mask-repeat: no-repeat) {
            mask-image: url($icon);
            mask-size: $w $h;
            background: currentColor;
        }
    }

    &.-edit::before {
        $icon: 'pen.svg';
        $w: 1.4rem;
        $h: 1.4rem;
        width: $w;
        height: $h;
        background-image: url($icon);
        background-size: $w $h;

        @supports (mask-repeat: no-repeat) {
            mask-image: url($icon);
            mask-size: $w $h;
            background: currentColor;
        }
    }

    &.-reply {
        display: block;
        margin-left: auto;
        justify-self: flex-end;

        &::before {
            $icon: 'arrow-return.svg';
            $w: 1.8rem;
            $h: 1.5rem;
            width: $w;
            height: $h;
            background-image: url($icon);
            background-size: $w $h;
            margin-right: .7rem;
            vertical-align: middle;

            @supports (mask-repeat: no-repeat) {
                mask-image: url($icon);
                mask-size: $w $h;
                background: currentColor;
            }
        }
    }
}

.reply__content {
    display: block;
    margin-bottom: 1rem;
}

.reply__text {
    display: block;
    margin-bottom: 1rem;
    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: 1.5rem;
    line-height: 1.6;
    color: var(--color-blue--light);

    &.-main {
        margin-bottom: 2rem;
    }

    &.-meta {
        font-weight: var(--font-weight--bold);
    }

    &.-title {
        text-transform: uppercase;
        font-weight: var(--font-weight--bold);
        margin-bottom: 0;
    }
}

.reply__title {
    display: block;
    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--bold);
    font-size: 2rem;
    line-height: 1.5;
    color: var(--color-blue--dark);
}

.reply__figure {
    display: flex;
    align-items: center;
}

.reply__footer {
    display: flex;
    align-items: center;
    width: 100%;
}

.reply__author {
    display: flex;
    align-items: center;
    margin-right: 2.2rem;
}

.reply__dropdown {
    display: block;
    margin: 0;
    padding: 1rem .7rem;

    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--medium);
    font-size: 1.6rem;
    line-height: (30/16);
    color: var(--color-blue-2);
    cursor: pointer;

    &:hover,
    &:focus {
        background: var(--color-gray);
    }

    &::before {
        $w: 2rem;
        $h: 2rem;
        $icon: 'speech-bubble.svg';

        content: '';
        transition: background .3s ease;

        display: inline-block;
        vertical-align: middle;
        width: $w;
        height: $h;
        margin-right: .7rem;

        background: url($icon) center center no-repeat;
        background-size: $w $h;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url($icon);
            mask-repeat: no-repeat;
            mask-size: $w $h;
        }
    }

    &::after {
        $w: 1.2rem;
        $h: .8rem;
        $icon: 'arrow.svg';

        content: '';
        transition: transform .3s ease, background .3s ease;
        transform: rotate(0);

        display: inline-block;
        width: $w;
        height: $h;
        margin-left: .5rem;

        background-image: url($icon);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: $w $h;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url($icon);
            mask-repeat: no-repeat;
            mask-size: $w $h;
        }
    }

    &.-open {
        &::after {
            transform: rotate(180deg);
        }
    }
}

.reply__dropdown-content {
    display: none;
    padding-top: 4rem;

    &.-open {
        display: block;
    }
}
