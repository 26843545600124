/* Figure */
.figure {
    display: flex;
    flex-direction: column;

    float: none;
    clear: both;
    width: 100%;
    margin: 0;
    margin-bottom: 4rem;

    &::after {
        display: table;
        float: none;
        clear: both;
        overflow: hidden;
        content: '';
    }

    &.center {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    > img {
        box-sizing: border-box;
        width: auto;
        max-width: 100%;
        margin: .5rem auto 1rem auto;
    }

    &.small {
        @media (--query-medium-extra-large) {
            max-width: 33%;
        }
    }

    &.medium {
        @media (--query-medium-extra-large) {
            max-width: 66%;
        }
    }

    &.left {
        @media (--query-medium-extra-large) {
            float: left;
            clear: both;
            margin-right: 3rem;
        }
    }

    &.right {
        @media (--query-medium-extra-large) {
            float: right;
            margin-left: 3rem;
        }
    }
}

figure {
    @extend .figure;
}

// These are classes that are assigned by the backend
.image-style-align-left {
    @media (--query-medium-extra-large) {
        width: auto;
        float: left;
        clear: both;
        margin-right: 3rem;
    }
}

.image-style-align-right {
    @media (--query-medium-extra-large) {
        width: auto;
        float: right;
        margin-left: 3rem;
    }
}
