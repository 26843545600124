.message-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1 1 auto;

    width: 100%;
    max-width: 83rem;
    margin-bottom: 7rem;

    @media (--screen-medium) {
        margin-right: 3rem;
    }

    @media (--screen-large) {
        margin-right: 5rem;
    }

    @media (--query-medium-extra-large) {
        padding-top: 3.8rem;
    }
}

.message-holder__title {
    display: block;
    margin: 0 0 2rem 0;
    color: var(--color-blue--dark);
}
