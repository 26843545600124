.overlay-wrapper {
    position: relative;
    margin-top: 4rem;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -100%;
        z-index: 0;
        display: block;
        width: 200vw;
        height: 50%;
        background: var(--color-gray-2);
    }
}
