.search {

}

.search__title {
    display: none;
    width: 100%;

    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--bold);
    font-size: 5rem;
    line-height: 1.26;
    color: var(--color-gray--darkest);

    @media (--screen-medium) {
        display: inline-block;
        margin-left: 4rem;
    }
}
