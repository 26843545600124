.report-attachment {
    @include white-box();
    @extend .-animated;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 2.6rem 0;
    border-top: .1rem solid var(--color-gray--dark);
    border-bottom: .1rem solid var(--color-gray--dark);

    &::before,
    &::after {
        opacity: 0;
    }

    &:hover {
        border-color: var(--color-gray);

        > * {
            transform: none;
        }
    }
}

.report-attachment__info {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 1rem;
}

.report-attachment__report-image {
    margin-right: 4.6rem;
}

.report-attachment__title {
    margin-bottom: 1rem;
    color: var(--color-blue);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size);
    font-family: var(--font-quicksand);
    line-height: 1.3;
}

.report-attachment__date {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.report-attachment__date-label {
    color: var(--color-blue--light);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--extra-small);
    font-family: var(--font-roboto);
}

.report-attachment__date-icon {
    width: 1.6rem;
    height: 1.8rem;
    margin-right: .4rem;
}

.report-attachment__link {
    @extend .button;
    @extend .-has-shadow;
}
