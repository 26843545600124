.tag {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    padding: .4rem 1rem;
    border-radius: 1.5rem;

    font-family: var(--font-roboto);
    font-weight: var(--font-weight--medium);
    font-size: var(--font-size--smaller);
    color: var(--color-white);

    // Themes
    .-blue &,
    .-medizorg & {
        background-color: var(--color-medizorg);
    }

    .-green &,
    .-corent & {
        background-color: var(--color-corent);
    }

    .-pink &,
    .-covent & {
        background-color: var(--color-covent);
    }

    .-orange &,
    .-covimmo & {
        background-color: var(--color-covimmo);
    }

    .-purple &,
    .-mz-consult & {
        background-color: var(--color-mz-consult);
    }

    .-lightgrey &,
    .-personalia & {
        padding-left: .5rem;
        color: var(--color-blue--light);
        line-height: 0;
        background-color: var(--color-personalia);

        &::before {
            content: '';
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
            margin-right: .6rem;
            border-radius: 50%;
            background: var(--color-blue--light) url('../Images/svg/icon-person.svg') center center no-repeat;
            background-size: .8rem;
        }
    }

    .-grey &,
    .-algemeen & {
        background-color: var(--color-algemeen);
    }

    // Context
    &.-agenda-entry {
        margin-right: 2rem;
    }

    .-large & {
        margin-left: 0;
    }
}
