.form {
    &.-rounded-box {
        box-sizing: border-box;
        display: block;
        width: 100%;
        background: var(--color-white);
        padding: 2rem;
        border-radius: 3.25rem;
        box-shadow: 0 10px 24px 0 rgba(0,0,0,0.20);

        @media (--query-large-extra-large) {
            padding: 4rem 2rem 2rem 4rem;
        }
    }

    &.-boxed {
        @include white-box();
        box-sizing: border-box;
        padding: 6rem 4rem;
    }

    .search-bar & {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;

        > :first-child {
            flex: 1 1 auto;
        }

        @media (--screen-medium) {
            flex-direction: row;
        }
    }

    .search-status-container {
        flex-grow: 2;
        padding-left: 35px;
        width: 100%;
    }

    &.-filter {
        @media (--query-mini-medium) {
            flex-direction: column;
        }
    }
}

.label_white {
    color: var(--color-gray--light)
}

.form__actions {
    padding-top: 1rem;

    > * {
        &:not(:last-child) {
            margin-right: 3.7rem;
        }
    }
}

.form__link {
    color: var(--color-gray--darkest);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--smaller);
    font-family: var(--font-roboto);
    line-height: 1.4;
    text-decoration: underline;
}
