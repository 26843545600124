.box {
    display: block;
    width: 100%;
    max-width: 37.3rem;
    margin: 0 0 4rem;
    padding: 2.5rem 2.8rem;

    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: 1.5rem;
    line-height: 1.6;
    color: var(--color-blue--light);

    background: var(--color-white);
    box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, .1);
    border-radius: .4rem;

    > a:not([class]) {
        color: var(--color-blue);
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }

    @media (--query-medium-extra-large) {
        margin-left: 4.8rem;
    }
}

.box__image {
    transition: background-color .3s ease;
    background: var(--color-gray);

    display: block;
    width: 100%;
    height: 100%;
    max-width: 6.1rem;
    max-height: 6.1rem;
    margin: 0 1.4rem 1.4rem 0;
    padding: 0;
    border-radius: 50%;
}

.box__title {
    margin-bottom: .5rem;
    font-family: var(--font-roboto);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size);
    line-height: 1.18;
    color: var(--color-gray--darkest);
    text-transform: uppercase;
}

.box__subtitle {
    display: block;
    margin-bottom: 1rem;
    padding-bottom: .5rem;
    color: var(--color-gray--darkest);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--smallest);
    font-family: var(--font-roboto);
    line-height: 1.15;
}

.box__phone {
    color: var(--color-blue--light);
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
