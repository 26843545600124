%form__select-reset {
    transition: all .3s ease;
    box-sizing: border-box;
    border-radius: .4rem;

    -webkit-appearance: none !important; // HARD reset to remove shadow in input for IOS

    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 1.4rem 1.5rem;
    vertical-align: baseline;

    font-family: var(--font-roboto);
    font-weight: var(--font-weight--bold);
    font-size: 1.6rem;
    line-height: 1.8;

    color: var(--color-blue);
    background: var(--color-white);
    border: .2rem solid var(--color-gray--dark);

    // Firefox outline style
    outline: none;
}

.form__select {
    position: relative;
    background: var(--color-white);

    &::before {
        content: '';
        transition: background .3s ease;

        position: absolute;
        top: calc(50% - .4rem);
        right: 2.4rem;
        z-index: 1;

        display: inline-block;
        width: 1.3rem;
        height: .8rem;

        background: currentColor;
        mask-image: url('arrow.svg');
        mask-repeat: no-repeat;
        mask-size: 1.3rem .8rem;
    }

    > .form__select-input {
        @extend %form__select-reset;
        position: relative;
        z-index: 2;
        background: none;
        padding-right: 3.6rem;
        cursor: pointer;

        &:invalid {
            color: var(--color-red);
        }
    }

    &.-has-value,
    &[data-component='Form/hasValue']:focus {
        > .form__select-input {
            $shift: 1rem;
            padding-top: (1.4rem + $shift);
            padding-bottom: (1.4rem - $shift);
        }

        & ~ .form__label {
            font-size: 1.4rem;
            font-weight: var(--font-weight--regular);
            transform: translateY(1rem);
        }
    }
}
