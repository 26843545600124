.image-upload {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 4.7rem;

    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: 1.7rem;
    line-height: 1.7;
    color: var(--color-blue--light);

    img {
        display: block;
        margin-right: 1.4rem;
    }

    input[type="checkbox"] {
        display: none;
    }
}

.image-upload__image {
    display: flex;
    align-items: center;
    justify-self: flex-start;
    width: 50%;
    margin-right: auto;
    margin-bottom: 2rem;
}


.image-upload__actions {
    justify-self: flex-end;
    margin-bottom: 2rem;
}
