.form__label{
    order: 2;

    display: block;
    cursor: pointer;

    color: var(--color-black);
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.5;
    padding: 0 0 .5rem;

    transition: all .2s ease;


    &.-placeholder-label {
        order: 1;

        transform: translateY(2.2rem);
        position: absolute;
        top: 0;

        box-sizing: border-box;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        padding-left: 1.6rem;

        font-family: var(--font-roboto);
        font-weight: var(--font-weight--bold);
        font-size: 1.6rem;
        line-height: 1;
        color: var(--color-blue--light);
        padding-right: 4rem;
    }

    &.-radio,
    &.-radio-button,
    &.-checkbox {
        display: block;
        margin: 0 0 2rem 0;
        padding: 0 0 0 (1.8rem + 1.3rem);

        font-family: var(--font-roboto);
        font-weight: var(--font-weight--regular);
        font-size: 1.6rem;
        line-height: 1;
        color: var(--color-blue--light);
    }

    &.-select {
        pointer-events: none;
    }

    &.-no-style {
        display: initial;
        font: inherit;
        color: inherit;
        line-height: inherit;
        margin: 0;
        padding: 0;
    }

    &.-pointer {
        cursor: pointer;
    }
}

[readonly] + label {
    cursor: default;
}
