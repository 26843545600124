.paragraph ,
p {
    display: block;
    margin: 0 0 2.7rem 0;

    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: 1.7rem;
    line-height: 1.7;
    color: var(--color-blue--light);

    &.-summary {
        font-weight: bold;
        font-size: 1.8rem;
    }

    &.-small {
        color: var(--color-gray);
        font-weight: normal;
        font-size: 1.4rem;
    }
    
    &.-gray {
        color: var(--color-blue--light);
    }

    &.-white {
        color: var(--color-white);
    }

    &.-maxed-47 {
        max-width: 47rem;
    }

    &.-fixed-width {
        max-width: 51.7rem;
    }

    &.-centered {
        margin-left: auto;
        margin-right: auto;
    }
}
