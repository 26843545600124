/*.intranet-header {
    z-index: 3;
    width: 100%;
    height: 21.8rem;
    background: linear-gradient(to right, rgba(96, 168, 255, .2) 0%, rgba(48, 35, 174, .2) 100%);

    + main {
        padding-top: 5rem;
    }

    @media (--screen-medium) {
        height: auto;
    }
}

.intranet-header__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}*/

.intranet-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    @media (--query-mini-medium) {
        padding-bottom: 1.6rem;
    }

    @media screen and (max-width: 1034px) {
        justify-content: space-around;
    }
}

.intranet-header__logo {
    display: none;
    margin-left: 3.3rem;

    @media (--screen-medium) {
        display: inline-block;
    }
}

.intranet-header__title {
    width: 100%;
    color: var(--color-blue);
    text-align: center;
}

.intranet-header__mobile {
    position: relative;
    display: none;
    width: 100%;
    padding-top: 1.6rem;

    @media (--query-mini-medium) {
        display: flex;
    }
}

.intranet-header__top-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (min-width: 1035px) {
        margin-left: auto;
    }
}
