address,
.address {
    font: inherit;

    &.-block {
        display: block;
        max-width: 37.3rem;
        margin: 0 0 4rem;
        padding: 3.2rem 5.4rem;

        font-family: var(--font-roboto);
        font-weight: var(--font-weight--regular);
        font-size: 1.5rem;
        line-height: 1.6;
        color: var(--color-blue--light);

        background: var(--color-white);
        box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, .1);
        border-radius: .4rem;

        > *:last-child {
            margin-bottom: 0;
        }
    }
}

.address__title {
    display: block;
    margin: 0 0 1rem;

    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--bold);
    font-size: 1.7rem;
    line-height: 1.2;
    color: var(--color-gray--darkest);
    text-transform: uppercase;
}

.address__subtitle {
    display: block;
    margin: 0;

    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: 1.3rem;
    line-height: 1.5;
    color: var(--color-gray--darkest);
    letter-spacing: .05rem;
}

.address__text {
    display: block;
    margin: 0 0 2rem;

    > a {
        display: block;

        &.-no-style {
            display: inline;
        }
    }
}
