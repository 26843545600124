.logo {
    max-width: 28.2rem;

    svg {
        display: block;
        width: 100%;
        height: auto;

        @media (--query-mini-medium) {
            max-width: 19.7rem;
            max-height: 4.4rem;
        }
    }

    &.-intranet {
        margin-left: 3.3rem;
        max-width: 23.3rem;
        @media (--query-mini-medium) {
            display: none;
        }
    }

    @media (--query-mini-medium) {
        position: absolute;
        top: 1.6rem;
        left: 2rem;
    }

    &.-static,
    &.-menu  {
        position: static;
    }

    &.-static {
        margin-right: auto;
    }
}

.logo__text {
    @media (--query-mini-medium) {
        display: none;
    }
}

.logo__icon {
    .logo.-menu & {
        fill: var(--color-white);
    }
}
