.add-to-calendar {
    &.-agenda-entry {
        z-index: 1;
    }

    &.-button {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        label {
            color: var(--color-blue--light);
            font-weight: var(--font-weight--medium);
            font-size: var(--font-size--smaller);
            font-family: var(--font-roboto);
            line-height: 1.5;
            text-transform: uppercase;
            text-decoration: none;
            pointer-events: none;
        }

        input {
            display: none;
        }

        &::before {
            content: '';
            transition: background .3s ease;

            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
            margin-right: .6rem;

            background: currentColor;
            mask-image: url('../Images/svg/icon-add-to-calendar.svg');
            mask-repeat: no-repeat;
            mask-size: 1.4rem 1.4rem;
        }
    }
}

.add-to-calendar-list {
    @include tooltip();
    transition: opacity .3s ease;
    opacity: 1;

    position: absolute;
    top: 100%;
    left: 50%;
    z-index: -9999;
    transform: translateX(-50%);

    display: flex;
    flex-direction: column;
    width: 13.7rem;
    max-height: 0;
    margin: .9rem 0 0 0;
    padding: 0;

    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: -.9rem;
        left: 0;

        display: block;
        width: 100%;
        height: 1rem;
    }

    &::after {
        content: '';

        position: absolute;
        top: -.9rem;
        left: 50%;
        transform: translateX(-50%);

        display: block;
        width: 0;
        height: 0;

        border: .5rem solid transparent;
        border-bottom-color: var(--color-blue--light);
    }

    &:hover,
    .add-to-calendar.-open & {
        opacity: 1;
        z-index: 1;
        padding: 1.2rem 0;
        max-height: 100vh;
        overflow: visible;

        // not enough time to fix this
        a {
            padding: .3rem .9rem;
        }
    }

    a {
        color: var(--color-white);
        text-decoration: none;
        transition: background-color .3s ease;

        &:hover {
            background-color: var(--color-blue--dark);
        }
    }
}
