.office__title {
    margin-bottom: .8rem;
    color: var(--color-black);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--large);
    font-family: var(--font-quicksand);

    > a {
        display: inline-block;
        font: inherit;
        color: inherit;
        text-decoration: inherit;
        cursor: pointer;
    }

    .office__count {
        font-weight: var(--font-weight--regular);
    }
}

.office__location {
    color: var(--color-blue--light);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--smaller);
    font-family: var(--font-roboto);
    text-transform: uppercase;
}

.office__icon {
    width: 1rem;
    margin-right: .5rem;
    position: relative;
    top: .1rem;
}

.office__info {
    margin-bottom: 4rem;
    padding-bottom: 1.5rem;
    border-bottom: .1rem solid var(--color-gray--dark);
}

.office__specialists {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 -2.4rem;

    @media (--query-mini-small) {
        width: calc(100% + 2.4rem);
    }

    &.-centered {
        align-items: stretch;
        justify-content: center;
    }

    .-specialists-overview & {
        width: 100%;
        max-width: 31.2rem;
        margin: 0 auto;

        @media (min-width: 724px) {
            max-width: 62.4rem;
        }

        @media (min-width: 1036px) {
            max-width: 93.6rem;
        }

        @media (min-width: 1296px) {
            width: auto;
            max-width: none;
            margin: 0 -2.4rem;
        }
    }
}
