.agenda-entry {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 3rem;
    padding-top: .8rem;
    text-decoration: none;

    &:hover {
        .agenda-entry__info::before,
        .agenda-entry__title,
        .agenda-entry__time {
            opacity: .6;
        }
    }

    @media (--query-medium-extra-large) {
        max-width: 35rem;
    }
}

.agenda-entry__date {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.7rem;
    color: var(--color-blue--light);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--extra-small);
    font-family: var(--font-roboto);
}

.agenda-entry__icon {
    width: 1.3rem;
    margin-right: .6rem;
}

.agenda-entry__info {
    position: relative;

    display: flex;
    flex-direction: column;

    padding: 0 2rem;

    &::before {
        content: '';

        transition: opacity .5s ease;

        position: absolute;
        top: 0;
        left: 0;

        width: .3rem;
        height: 100%;

        background-color: var(--color-gray--dark);

        .-blue &,
        .-medizorg & {
            background-color: var(--color-medizorg);
        }

        .-green &,
        .-corent & {
            background-color: var(--color-corent);
        }

        .-pink &,
        .-covent & {
            background-color: var(--color-covent);
        }

        .-orange &,
        .-covimmo & {
            background-color: var(--color-covimmo);
        }

        .-purple &,
        .-ms-consult & {
            background-color: var(--color-mz-consult);
        }

        .-grey &,
        .-algemeen & {
            background-color: var(--color-algemeen);
        }

        .-lightgrey &,
        .-personalia & {
            background-color: var(--color-personalia);
        }
    }
}

.agenda-entry__head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-bottom: .5rem;
    order: 2;

    @media (--query-medium-extra-large) {
        order: 1;
    }
}

.agenda-entry__time {
    display: block;
    margin-right: .7rem;
    color: var(--color-gray--darkest);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--extra-small);
    font-family: var(--font-roboto);
    transition: opacity .3s ease;
}


.agenda-entry__body {
    transition: opacity .3s ease;

    display: block;
    width: 100%;
    max-width: 20rem;
    margin: 0 0 .8rem;

    flex: 1 0 100%;
    order: 0;

    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--bold);
    font-size: 1.5rem;
    line-height: 1.2;
    color: var(--color-gray--darkest);
    text-decoration: none;

    &::after {
        content: '';

        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        display: block;
        width: 100%;
        height: 100%;
    }

    @media (--screen-medium) {
        order: 2;
    }
}
