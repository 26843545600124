.form__message {
    position: relative;
    box-sizing: border-box;
    display: block;

    margin-top: 0;

    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: 1.4rem;
    font-style: italic;
    line-height: 1.5;
    color: var(--color-red);
    opacity: 0;
    padding: .5rem 1rem 1rem 1.6rem;
    transform: scaleY(0);
    overflow: hidden;

    transition: all .2s ease;

    .-invalid & {
        opacity: 1;
        transform: scaleY(1);
    }

    .-success & {
        opacity: 1;
        transform: scaleY(1);
        color: var(--color-purple);
    }

    &.-hide {
        opacity: 0;
        transform: scaleY(0);
        position: absolute;
        z-index: -1;
    }
}
