.form__checkbox {
    border: 0;
    display: none;

    + .form__label {
        position: relative;

        &::before,
        &::after {
            content: '';

            display: block;
            width: 1.8rem;
            height: 1.8rem;

            position: absolute;
            top: -.2rem;
            left: 0;
        }

        &::before {
            transition:
                box-shadow .2s ease,
                background-color .2s ease;
            box-shadow: inset 0 0 0 .2rem var(--color-blue);
            border-radius: .4rem;
        }

        &::after {
            transition:
                opacity .3s ease,
                transform .2s ease;

            color: var(--color-white);
            z-index: 1;
            opacity: 0;
            transform: scale(2);

            background: currentColor;
            mask-image: url(checkmark.svg);
            mask-repeat: no-repeat;
            mask-size: 1.5rem 1.1rem;
            mask-position: center center;
        }
    }

    &:checked + .form__label::before {
        box-shadow: inset 0 0 0 .2rem var(--color-blue);
        background-color: var(--color-blue);
    }

    &:checked + .form__label::after {
        opacity: 1;
        transform: scale(.8);
    }
}
