.partnership-holder {
    display: flex;
    width: 100%;
    flex-grow: 1;
}

.partnership-holder__outer {
    position: relative;
    width: 100%;
    padding: .8rem 0 2.9rem;
    background-color: var(--color-gray-3);
    border-top-left-radius: 5.1rem;
    border-top-right-radius: 5.1rem;


    .-vennootschap-overview & {
        height: 100%;
        padding-top: 5rem;
    }

    &::before,
    &::after {
        position: absolute;
    }

    &::before {
        .-vennootschap-overview & {
            left: -9.6rem;
        }
    }

    &::after {
        right: -17.9rem;
        bottom: -17.1rem;
        content: url('../Images/image-virtual-reality-right.png');

        .-vennootschap-overview & {
            right: -24.3rem;
            bottom: -20rem;
        }
    }

    @media (--screen-extra-large) {
        &::before {
            bottom: -.2rem;
            left: -5rem;
            content: url('../Images/image-virtual-reality-left.png');
        }

        &::after {
            right: -22rem;
            bottom: -2rem;
        }
    }
}

.partnership-holder__inner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 1.9rem;

    @media (--screen-extra-large) {
        margin: 0 2.7rem;
    }
}

.partnership-holder__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(100% + 2.5rem);
    margin-left: -2.5rem;
    padding-top: 4rem;
}

.partnership-holder__title {
    display: block;
    width: 100%;
    padding: 2rem .5rem 0 .5rem;
    margin-bottom: 0;
    color: var(--color-blue--dark);

    @media (--query-mini-medium) {
        text-align: center;
    }

    .-vennootschap-overview & {
        display: none;
    }
}
