h1, .title-1,
h2, .title-2,
h3, .title-3,
h4, .title-4,
h5, .title-5,
h6, .title-6 {
    display: block;
    margin: 0 0 1em 0;
    font-family: var(--font-quicksand);
    color: var(--color-blue--dark);

    a {
        color: var(--color-blue--dark);
        font-weight: inherit;
        font-size: inherit;
        font-family: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .heading-block & {
        margin-right: 9rem;
    }

    &.-white {
        color: var(--color-white);
    }
}

h1,
.title-1 {
    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--medium);
    font-size: 3.5rem;
    line-height: 1.26;
    color: var(--color-blue--dark);

    @media (--screen-large) {
        font-size: 5rem;
    }
}

h2,
.title-2 {
    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--regular);
    font-size: 3rem;
    line-height: 1.45;
    color: var(--color-blue--dark);

    @media (--screen-large) {
        font-size: 3.5rem;
    }
}

h3,
.title-3 {
    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--bold);
    font-size: 2.2rem;
    line-height: 1.3;
    color: var(--color-gray--darkest);

    @media (--screen-large) {
        font-size: 2.4rem;
    }

    &.-roboto {
        font-family: var(--font-roboto);
        font-weight: var(--font-weight--regular);
        line-height: 1.2;

        @media (--screen-large) {
            font-size: 2.8rem;
        }
    }

    &.-roboto-smaller {
        font-family: var(--font-roboto);
        font-weight: var(--font-weight--regular);
        line-height: 1.2;

        @media (--screen-large) {
            font-size: 2.5rem;
        }
    }
}

h4,
.title-4 {
    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--bold);
    font-size: 1.8rem;
    line-height: 1.25;
    color: var(--color-blue--dark);

    @media (--screen-large) {
        font-size: 2rem;
    }
}

h5,
.title-5 {
    margin: 0 0 2.5rem 0;
    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: 1.8rem;
    line-height: 1.2;
    color: var(--color-black-2);
}
