.account-activity {
    margin-bottom: 10rem;
}

.account-activity__entries {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 8.5rem 0 0;
    padding: 0;
    list-style-type: none;
}

.account-activity__entry {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 2.4rem 2.1rem;
    border-top: .1rem solid var(--color-gray--dark);

    @media (--screen-medium) {
        flex-wrap: no-wrap;
    }

    &:last-child {
        border-bottom: .1rem solid var(--color-gray--dark);
    }
}

.account-activity__date {
    flex: 1 1 auto;
    margin-right: 1rem;
    color: var(--color-gray--darkest);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--small);
    font-family: var(--font-roboto);

    @media (--screen-medium) {
        flex: 0 1 25.8rem;
    }
}

.account-activity__time {
    display: flex;
    flex: 0;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 1rem;

    @media (--screen-medium) {
        flex: 1 1 auto;
    }
}

.account-activity__time-icon {
    position: relative;
    top: .2rem;
    margin-right: 1rem;
    height: 1.4rem;
}

.account-activity__time-label {
    color: var(--color-blue--light);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--small);
    font-family: var(--font-roboto);
}

.account-activity__info {
    width: 100%;
    margin-top: 1rem;
    color: var(--color-blue);
    font-weight: var(--font-weight--medium);
    font-size: var(--font-size);
    font-family: var(--font-roboto);

    @media (--screen-medium) {
        width: auto;
        margin-top: 0;
    }
}

.account-activity__title {
    color: var(--color-blue);
    font-weight: var(--font-weight--regular);
}
