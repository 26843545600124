table {
    width: 100%;
    margin-bottom: 2rem;
    border-spacing: 0;
    background: var(--color-white);
    box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, .2);

    th {
        padding: 1.5rem 1rem;
        font-family: var(--font-quicksand);
        font-weight: var(--font-weight--bold);
        font-size: 1.7rem;
        line-height: 1.7;
        color: var(--color-blue--dark);
        background: var(--color-gray--dark);
        text-align: left;
    }

    td {
        padding: 1.5rem 1rem;
        vertical-align: top;
        font-family: var(--font-roboto);
        font-weight: var(--font-weight--regular);
        font-size: 1.6rem;
        line-height: 1;
        color: var(--color-blue--light);

        @media (--query-mini-medium) {
            display: block;

            &:empty {
                display: none;
            }
        }
    }

    tr {
        &:nth-child(odd) {
            background: var(--color-gray);
        }
    }
}

.table {
    overflow-y: auto;

    td {
        @media (--query-mini-medium) {
            display: table-cell;
        }
    }
}
