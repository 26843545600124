ul:not([class]),
ol:not([class]),
.list {
    display: block;
    margin: 0;
    margin-bottom: 4rem;
    padding: 0;
    list-style: none;

    //font-family: var(--font-default);
    //font-weight: var(--font-weight-normal);
    font-size: 1.7rem;
    line-height: 1.7;
    //color: var(--color-black);

    li {
        position: relative;
        padding: 0;
        margin-left: 2.5rem;
        //font-weight: var(--font-weight-normal);

        &::before {
            content: '\2022';
            color: var(--color-blue);
            font-weight: bold;
            display: inline-block;
            margin: 0 1rem 0 -2.5rem;
            width: 1rem;
        }
    }
}

ol:not([class]) {
    li {
        counter-increment: li;

        &::before {
            content: counter(li) '.';
            font-size: 1.5rem;
            text-align: right;
            width: 4rem;
            margin-left: -5rem;
        }
    }
}

.listing-list_icon {
    max-width: 20px;
    margin-right: 10px;

    &.-file {
        max-width: 16px;
    }
}

.listing-list {
    padding-top: 5px;
    padding-bottom: 5px;
}

.listing-list_link {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.listing-list__child-link {
    text-decoration: none;
    color: black;
    border: 1px solid transparent;
    display: inline-flex;
    align-items: center;
    padding: 5px;

    &:hover {
        border: 1px solid gray;
        border-radius: 5px;
    }
}
