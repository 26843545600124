// This component was created because of the previous FE-er's components were so chaotic, they were not so easy to reuse
// Writing this component was easier then to figure out how all others worked
.overview {
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    &.-flex-gutter-5 {
        width: calc(100% + 5rem);
        margin-left: -5rem;
    }

    &.-flex-gutter-4_8 {
        @media (--query-medium-extra-large) {
            width: calc(100% + 4.8rem);
            margin-left: -4.8rem;
        }
    }
}
