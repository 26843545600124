.footer {
    z-index: 1;
    background-color: var(--color-blue);
    overflow: hidden;
}

.footer__inner {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3.6rem 3.9rem;

    @media (--screen-medium) {
        padding: 8rem 0 0;
    }
}

.footer__column {
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: .8rem;

    &:first-child {
        margin-bottom: 4rem;
    }

    @media (--screen-small) {
        flex: 0 0 100%;
        margin-bottom: 3rem;

        &:first-child {
            margin-bottom: 3rem;
        }
    }

    @media (--screen-medium) {
        flex: 0 0 33%;
        padding-right: 5rem;

        &:first-child {
            flex: 0 0 100%;
            margin-bottom: 5.2rem;
        }

        &[data-content-type='seat'] {
            order: 1;
        }

        &[data-content-type='menu'] {
            order: 2;
        }

        &[data-content-type='extra'] {
            order: 3;
        }
    }

    @media (--screen-large) {
        flex: 0 0 25%;
        padding-right: 5rem;

        &:first-child {
            flex: 0 0 25%;
        }
    }
}

.footer__copyright {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    order: 4;
    width: 100%;
    margin-top: .5rem;
    padding-top: 3.6rem;
    color: var(--color-white);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--smaller);
    font-family: var(--font-roboto);
    line-height: 1.6;

    &::before {
        position: absolute;
        top: 0;
        left: 50%;
        width: 100vw;
        height: .1rem;
        background-color: var(--color-blue--dark);
        transform: translateX(-50%);
        content: '';
    }

    > :first-child {
        margin-right: 3rem;
    }

    @media (--screen-medium) {
        flex-direction: row;
        align-items: center;
        padding: 1.4rem 0;
    }

    @media (--screen-large) {
        > :first-child {
            margin-right: 5.2rem;
        }
    }
}

.footer__subtitle {
    margin-top: 1rem;
    color: var(--color-gray--light);
    text-transform: uppercase;
}

.footer__content {
    color: var(--color-gray--light);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--small);
    font-family: var(--font-quicksand);
    line-height: 1.8;
}

.footer__link {
    display: block;
    color: var(--color-white);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--small);
    font-family: var(--font-quicksand);
    line-height: 1.8;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.footer__background {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    transform: translateY(50%);

    @media (--screen-medium) {
        right: 10rem;
        width: auto;
    }
}
