.browser-update {
    display: none;
    margin: .2rem 0;
    padding: .2rem 1rem;
    color: #000000;
    text-align: center;
    background: #CCCCCC;

    /* stylelint-disable csstools/media-use-custom-media */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
    }
    /* styleline-enable */
}
