.filters {
    margin-bottom: 2rem;

    .form {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
    }
}
