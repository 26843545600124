.login {
    @include white-box();

    margin-bottom: 10rem;

    &.-confirmation {
        .form {
            display: none;
        }
    }

    @media (--screen-small) {
        padding: 7.4rem 4.8rem;
    }

    @media (--query-mini-small) {
        background: none;

        // this is needed because for some fking reason the background and shadows needs to be set using
        // a fking pseudo-element... WHY FFS???
        &::before,
        &::after {
            display: none;
        }
    }
}

.login__title {
    margin-bottom: 5.5rem;
    color: var(--color-blue--dark);
    text-align: center;
}

.login__intro {
    margin-bottom: 7rem;
    color: var(--color-blue);
    font-size: var(--font-size--larger);
    font-family: var(--font-roboto);
    line-height: 1.7;
    text-align: center;
}

.login__message {
    display: none;

    .-confirmation & {
        display: block;
        color: var(--color-blue--dark);
        font-weight: var(--font-weight--medium);
        font-size: var(--font-size);
        font-family: var(--font-roboto);
        line-height: 1.5;
        text-align: center;
    }
}
