.navigation {
    display: flex;
    flex-direction: row;

    &.-mobile-overlay-meta,
    &.-mobile-overlay-main {
        margin-bottom: 4rem;
    }

    &.-header-main {
        display: none;

        @media (--screen-medium) {
            display: flex;
            flex: 1 1 100%;
            justify-content: flex-end;
        }
    }

    &.-header-meta {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        justify-content: flex-end;
        justify-self: flex-end;
        padding: 2.7rem 0;
    }

    &.-centered-mobile {
        @media (--query-mini-medium) {
            justify-content: center;
        }
    }

    &.-header-intranet-top {
        display: none;
        flex: 1 1 auto;
        justify-content: flex-end;

        @media (--screen-medium) {
            display: flex;
        }
    }

    &.-header-intranet-main {
        display: none;
        flex: 1 1 100%;
        justify-content: flex-end;
        height: 5.9rem;
        margin: 2.5rem 0 1.7rem;
        padding: 0 4rem 0 3.3rem;
        background-color: var(--color-white);
        border-radius: 2.95rem;

        box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, .2);

        @media (--screen-medium) {
            display: flex;
        }
    }

    &.-header-intranet-user {
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
        width: 100%;
        background-color: var(--color-white);
        border: .1rem solid var(--color-gray--dark);
        border-radius: .8rem;

        .-open & {
            display: inline-block;
        }

        &::before {
            position: absolute;
            top: -.5rem;
            left: 3rem;
            z-index: -1;
            width: 1rem;
            height: 1rem;
            background-color: var(--color-white);
            border: .1rem solid var(--color-gray--dark);
            transform: rotate(45deg);
            content: '';
        }

        &::after {
            position: absolute;
            top: -.1rem;
            left: 3.1rem;
            width: 1rem;
            height: .1rem;
            background-color: var(--color-white);
            content: '';
        }
    }

    &.-copyright {
        flex: 1 1 auto;
    }

    &.-copyright--alt {
        flex: 1 1 auto;
        order: 2;

        @media (--screen-extra-large) {
            order: 1;
        }
    }

    &.-footer--alt {
        order: 1;

        @media (--screen-extra-large) {
            order: 2;
        }
    }
}

.navigation__items {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    list-style-type: none;

    @media (--query-mini-medium) {
        align-self: center;
    }

    .-mobile-overlay-meta &,
    .-mobile-overlay-main & {
        flex-direction: column;
    }

    .-header-intranet-main & {
        width: 100%;
        margin: 0;
    }

    .-header-intranet-user & {
        flex-direction: column;
        margin: 0;
        overflow: hidden;
        border-radius: .8rem;
    }

    .-footer & {
        flex-direction: column;
    }

    .-copyright & {
        flex: 1 1 auto;
        flex-direction: column;
        margin: 1rem 0 3.7rem;

        @media (--screen-medium) {
            flex-direction: row;
            margin: 0;
        }
    }

    .-copyright--alt & {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;

        @media (--screen-small) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        @media (--screen-extra-large) {
            margin: 0 0 0 7rem;
        }
    }
}

.navigation__item {
    .-mobile-overlay-main & {
        &:not(:last-child) {
            margin-bottom: 3rem;
        }
    }

    .-mobile-overlay-meta & {
        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        img {
            display: none;
        }
    }

    .-header-main & {
        &:not(:last-child) {
            margin-right: 3.9rem;
        }
    }

    .-header-intranet-top & {
        margin-right: 2rem;

        @media screen and (min-width: 1035px) {
            margin-right: 4.5rem;

            &:first-child {
                padding-left: 1rem;
            }
        }
    }

    .-header-intranet-main & {
        margin-right: 2rem;

        @media screen and (min-width: 980px) {
            margin-right: 5rem;
        }

        &:first-child {
            margin-right: auto;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .-header-intranet-user & {
        &:not(:last-child) {
            border-bottom: .1px solid var(--color-gray--dark);
        }
    }

    .-copyright & {
        margin-right: 7rem;
        margin-bottom: 1rem;

        @media (--screen-medium) {
            margin-bottom: 0;

            &:last-child {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }

    .-copyright--alt & {

        &:last-child {
            margin: 2rem 3rem 1rem;
        }

        @media (--screen-small) {
            margin: 1rem 3rem;

            &:last-child {
                margin: 1rem 3rem;
            }
        }

        @media (--screen-extra-large) {
            margin: 0 7rem 0 0;

            &:last-child {
                margin: 0 7rem 0 0;
            }
        }
    }
}
