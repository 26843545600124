.detail-navigation-bar {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    max-width: 83rem;
    margin: 0 auto;
    margin-bottom: 5rem;
    padding: 0;

    @media (--query-medium-extra-large) {
        margin-bottom: 10rem;
    }
}

.detail-navigation-bar__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 22rem;
    min-height: 4.6rem;
    text-decoration: none;

    &.-prev,
    &.-next {
        &::before,
        &::after {
            content: '';
            transition: background .3s ease;
            position: absolute;
            display: block;
            border-radius: 100%;

            top: 50%;
            transform: translateY(-50%);
        }

        &::before {
            bottom: 0;
            width: 4.6rem;
            height: 4.6rem;
            background: var(--color-blue);
        }

        &::after {
            bottom: 1.7rem;
            width: .8rem;
            height: 1.3rem;
            background: var(--color-white);
            mask-image: url(arrow.svg);
            mask-repeat: no-repeat;
            mask-size: .8rem 1.3rem;
        }
    }

    &.-prev {
        padding-left: 6.6rem;

        &::before {
            left: 0;
        }

        &::after {
            left: 1.9rem;
        }
     }

    &.-next {
        margin-left: auto;
        padding-right: 6.6rem;
        text-align: right;
        align-items: flex-end;

        &::before {
            right: 0;
        }

        &::after {
            right: 1.9rem;
            transform: rotate(180deg) translateY(50%);
        }
    }

    &:hover,
    &:focus {
        &::before {
            background-color: var(--color-blue--dark);
        }
    }
}

.detail-navigation-bar__link {
    position: relative;
    display: inline-block;
    margin: 0;

    //font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.26;
    color: var(--color-blue--light);
    text-transform: uppercase;
}

.detail-navigation-bar__title {
    display: none;
    margin: 0;
    //font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.2;
    color: var(--color-blue);

    @media (min-width: 560px) {
        display: block;
    }
}
