/* Body */
body {
    position: relative;
    display: flex;
    flex-direction: column;

    box-sizing: border-box;

    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    overflow: hidden;
    word-wrap: break-word; // Don't let long words break the layout on small area's
    background-color: var(--color-gray-2);

    /*default font styling*/
    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: 1.4rem;
    line-height: 1.2;
    color: var(--color-blue--light);

    &.-no-overflow {
        position: fixed;
        height: 100vh;
        overflow: hidden;

        /* Prevent iOS safari from still showing main content */
        @supports (-webkit-overflow-scrolling: touch) {
            main {
                opacity: 0;
            }
        }
    }

    &.-mobile-menu-open {
        position: fixed;
        overflow: hidden;
    }

    &::before {
        content: '';
    }
}
