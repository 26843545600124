.error-text {
    display: block;
    margin-bottom: 1.6rem;
    font-family: var(--font-roboto);
    font-weight: normal;
    font-size: 1.4rem;
    font-style: italic;
    line-height: 2;
    color: var(--color-red);
}
