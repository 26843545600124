article,
.article {
    box-sizing: border-box;
    display: block;
    width: 100%;
    max-width: calc(61.1rem + 20rem); // includes padding
    margin: 0 auto;
    margin-bottom: 2rem;

    @media (--query-medium-extra-large) {
        margin-bottom: 8rem;
        padding-left: 10rem;
        padding-right: 10rem;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    > table {
        width: calc(100% + 20rem);
        margin-left: -10rem;
    }
}
