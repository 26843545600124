.welcome-message {
    display: flex;
    flex: 1 1 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 3.3rem 0 0;

    @media (--screen-medium) {
        margin: 3.3rem 0 0 6.5rem;
    }
}

.welcome-message__image {
    display: none;
    width: 9.2rem;
    height: 9.2rem;
    margin-right: 1.4rem;
    border-radius: 100%;

    @media (--screen-medium) {
        display: inline-block;
    }
}

.welcome-message__text {
    color: var(--color-blue);
    font-weight: var(--font-weight--medium);
    font-size: 2.8rem;
    font-family: var(--font-quicksand);
}
