.button-group {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    > :not(:last-child) {
        margin-right: 1rem;
    }

    &.-wide-space {
        > :not(:last-child) {
            margin-right: 3rem;
        }
    }

    &.-right-aligned {
        justify-content: flex-end;
    }
}
