.avatar {
    display: block;
    margin: 0;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 100%;

    &.-small {
        width: 1.6rem;
        height: 1.6rem;
    }

    &.-large {
        width: 5.9rem;
        height: 5.9rem;
    }
}
