.card {
    display: block;
    margin: 0 0 1rem;
    padding: 2rem;
    border-radius: .4rem;
    background: var(--color-white);
    border: .2rem solid var(--color-gray--dark);

    @media (--query-medium-extra-large) {
        padding: 4rem 5rem;
        margin: 0 0 2rem;
    }

    &.-small {
        padding: 1.3rem 2rem;
    }
}

.card__text {
    display: block;
    margin-bottom: 1rem;
    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: 1.5rem;
    line-height: 1.6;
    color: var(--color-blue--light);

    &.-main {
        margin-bottom: 2rem;
    }

    &.-meta {
        font-weight: var(--font-weight--bold);
    }

    &.-title {
        text-transform: uppercase;
        font-weight: var(--font-weight--bold);
        margin-bottom: 0;
    }
}

.card__title {
    display: block;
    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--bold);
    font-size: 2rem;
    line-height: 1.5;
    color: var(--color-blue--dark);
}

.card__figure {
    display: flex;
    align-items: center;
}

.card__image {
    display: block;
    margin: 0 1.6rem 0 0;
    background-color: var(--color-gray);
    border-radius: 100%;
}

