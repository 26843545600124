.documents-holder {
    margin-bottom: 4rem;

    @media (--query-medium-extra-large) {
        margin-bottom: 8.8rem;
    }
}

.documents-holder__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.documents-holder__title {
    color: var(--color-blue);
    text-align: center;
}
