.form__password-toggler {
    position: absolute;
    top: .9rem;
    right: .9rem;

    display: block;
    width: 2.5rem;
    height: 1.5rem;
    margin: 0;
    padding: 2rem;

    background: none;
    border: 0;
    font: 0/0 sans-serif;

    cursor: pointer;

    &::before,
    &::after {
        content: '';

        transition: opacity .3s ease;
        position: absolute;
        top: 0;
        left: 0;

        display: block;
        width: 100%;
        height: 100%;
        background-color: transparent;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 2.2rem;
    }

    &::before {
        background-image: url('../Images/svg/icon-visibility-off.svg');
        opacity: 1;
    }

    &::after {
        background-image: url('../Images/svg/icon-visibility.svg');
        opacity: 0;
    }

    &.-password-visible {
        &::before {
            opacity: 0;
        }

        &::after {
            opacity: 1;
        }
    }
}
