.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip__content {
    position: absolute;
    bottom: calc(100% + .6rem);
    left: 50%;
    transform: translateX(-50%);

    display: none;
    padding: 1rem;
    background: var(--color-blue--light);
    border-radius: .7rem;

    font-family: var(--font-roboto);
    font-weight: var(--font-weight--bold);
    font-size: 1.4rem;
    line-height: (16/14);
    text-transform: uppercase;
    color: var(--color-white);

    &::before {
        content: '';
        position: absolute;
        bottom: -1.2rem;
        left: 50%;
        transform: translateX(-50%);

        display: block;
        width: 0;
        height: 0;
        border: .6rem solid transparent;
        border-top-color: var(--color-blue--light);
    }

    .tooltip.-open & {
        display: block;
    }
}
