img {
    &.-rounded {
        border-radius: 50%;
    }

    &.-auto {
        width: auto;
        height: auto;
        max-width: 100%;
    }
}
