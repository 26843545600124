/**
  This is the helper class.
  These are styles that can be applied to any component in general

  All helper classes should be prefixed with .-helper-
 */

.-helper-hide {
    display: none !important;
}

.-helper-centered-text {
    text-align: center;
}

@for $i from 0 through 10 {
    .-helper-margin-top-#{$i} {
        margin-top: #{$i}rem !important;
    }

    .-helper-margin-bottom-#{$i} {
        margin-bottom: #{$i}rem !important;
    }

    .-helper-margin-right-#{$i} {
        margin-right: #{$i}rem !important;
    }

    .-helper-margin-left-#{$i} {
        margin-left: #{$i}rem !important;
    }
}

.-helper-margin-right-06 {
    margin-right: .6rem !important;
}
