/* ==========================================================================
    Colors

    This file is exclusively intended for setting up variables
    Never add styles directly to this file
    ========================================================================== */

:root {
    // Native Colors
    --color-white: #FFFFFF;
    --color-black: #000000;
    --rgb-black: 0,0,0;
    --color-black-2: #000A12; // h5, form__legend

    --color-blue--lighter: #A6D5FF;
    --color-blue--light: #6C8295;
    --color-blue: #2459A5;
    --color-blue--dark: #214A84;
    --color-blue--darkest: #272B3E;

    --color-blue-2: #6B8295; // forum-post__text
    --color-blue-3: #6A8197; // forum-head__text

    --color-green--lighter: #E8FAF1;
    --color-green--light: #61B98B;
    --color-green: #32BF76;
    --color-green--dark: #559172;

    --color-pink: #E62A7A;
    --color-pink--dark: #C12670;

    --color-yellow: #F39B55;
    --color-yellow--dark: #C9844E;

    --color-purple: #613A8E;
    --color-purple--dark: #4E336E;

    --color-red--light: #FFE2E2;
    --color-red: #FF4949;

    --color-gray--lightest: #F8F9FB;
    --color-gray--light: #DBF0FF;
    --color-gray: #EAEFF3;
    --color-gray--dark: #D0DBE5; // th
    --color-gray--dark_20: rgba(208, 219, 229, .2);
    --color-gray--darkest: #1D1D22;

    --color-gray-2: #F7F8FC; // body
    --color-gray-3: #E9EEF8; // password-field, Reactions bg
    --color-gray-4: #97AABA; // download eye icon

    --color-orange: #CEA324; // password-field

    // Assigned Colors
    --color-default: var(--color-blue);
    --color-default--dark: var(--color-blue--dark);

    --color-medizorg: var(--color-blue);
    --color-medizorg--dark: var(--color-blue--dark);

    --color-corent: var(--color-green--light);
    --color-corent--dark: var(--color-green--dark);

    --color-covent: var(--color-pink);
    --color-covent--dark: var(--color-pink--dark);

    --color-covimmo: var(--color-yellow);
    --color-covimmo--dark: var(--color-yellow--dark);

    --color-mz-consult: var(--color-purple);
    --color-mz-consult--dark: var(--color-purple--dark);

    --color-algemeen: var(--color-blue--light);

    --color-personalia: var(--color-white);

    --color-alert--success: var(--color-green);
    --color-alert--success-light: var(--color-green--lighter);

    --color-alert--error: var(--color-red);
    --color-alert--error-light: var(--color-red--light);

    --color-alert--warning: var(--color-yellow);
}
