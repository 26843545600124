figcaption {
    position: relative;

    box-sizing: border-box;
    display: block;
    width: 100%;
    margin: 0 auto;
    margin-top: -1.4rem;
    padding: 1.5rem 2.4rem;
    clear: both;

    background: var(--color-gray-3);
    border-radius: 0 0 1.6rem 1.6rem;

    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: 1.5rem;
    font-style: italic;
    line-height: 1.4;
    color: var(--color-blue--dark);
    text-align: left;

    /*@media (--screen-medium-large) {
        margin-top: -4.4rem;
    }

    figure.small & {
        @media (--screen-medium-large) {
            margin-top: -3.4rem;
        }
    }

    figure:not(.small):not(.medium) & {
        @media (--screen-medium-large) {
            width: calc(100% - 3.2rem);
        }
    }*/
}
