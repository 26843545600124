.form__password-strength {
    transition: all .2s ease;
    display: block;
    width: calc(100% - .8rem);
    margin-left: .4rem;
    border-radius: .5rem;

    -webkit-appearance: none; /* Reset appearance */

    font: 0/0 sans-serif;
    transform: translateY(-.2rem);

    .form__entry.-too-short &,
    .form__entry.-very-weak &,
    .form__entry.-weak & {
        color: var(--color-red);
    }

    .form__entry.-good & {
        color: var(--color-orange);
    }

    .form__entry.-strong &,
    .form__entry.-very-strong & {
        color: var(--color-green);
    }

    &::before {
        content: '';
        position: relative;
        transition: all .2s ease;

        display: block;
        width: var(--width, 0%);
        height: .4rem;
        border-radius: .5rem;
        background: currentColor;
    }

    &::after {
        transition: all .2s ease;
        content: attr(data-label);
        display: inline-block;
        width: var(--width, 0%);
        min-width: fit-content;
        padding-top: .1rem;
        text-align: right;

        font-family: var(--font-roboto);
        font-weight: var(--font-weight--regular);
        font-size: 1.2rem;
        line-height: 1rem;
        color: currentColor;
        text-transform: uppercase;
    }

    /*@supports (&::-webkit-meter-bar) {
        &::-webkit-meter-bar { // container
            transition: all .2s ease;
            background: transparent;
            height: .4rem;
            margin-top: -.3rem;
            border-radius: .5rem;
        }

        &::-webkit-meter-even-less-good-value { // Very weak value
            transition: all .2s ease;
            background: var(--color-red);
        }

        &::-webkit-meter-suboptimum-value { // Good value
            transition: all .2s ease;
            background: var(--color-orange);
        }

        &::-webkit-meter-optimum-value { // Strong value
            transition: all .2s ease;
            background: var(--color-green);
        }

        .-over-9000 & {
            &::-webkit-meter-optimum-value { // Strong value
                transition: all .2s ease;
                background: var(--color-green);
                animation: .3s infinite alternate blink;
            }
        }
    }*/
}

@keyframes blink {
    from {
        background: var(--color-green);
    }

    to {
        background: var(--color-red);
    }
}
