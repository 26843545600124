.search-results-header {
    display: block;
    width: 100%;
    max-width: 98rem;
    margin: 0 auto;
    margin-bottom: 4rem;
    padding-top: 2.5rem;
}

.search-results-header__title {
    display: block;
    margin-bottom: .5rem;
    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: 2.5rem;
    line-height: 1;
    color: var(--color-gray--darkest);

    strong {
        font-weight: var(--font-weight--bold);
        color: var(--color-blue)
    }
}

.search-results-header__small {
    display: block;
    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: 1.5rem;
    line-height: 1;
    color: var(--color-blue--light);
}
