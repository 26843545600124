%form__field-reset {
    transition: all .3s ease;
    box-sizing: border-box;
    border-radius: .4rem;

    -webkit-appearance: none !important; // HARD reset to remove shadow in input for IOS

    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 1.4rem 1.5rem;
    vertical-align: baseline;

    font-family: var(--font-roboto);
    font-weight: var(--font-weight--bold);
    font-size: 1.6rem;
    line-height: 1.8;

    color: var(--color-blue);
    background: var(--color-white);
    border: .2rem solid var(--color-gray--dark);

    // Firefox outline style
    outline: none;
}

[name*="PeopleWithAScreenReaderShouldIgnoreThis"] {
    display: none !important;
}

// FF cursor pointer bug fix
input {
    cursor: pointer;
}

/*[type='date'] {
    &::-webkit-datetime-edit {
        transition: all .3s ease;
        opacity: 0;
    }

    &.-has-value,
    &:focus {
        &::-webkit-datetime-edit {
            opacity: 1;
        }
    }
}*/

.form__field {
    @extend %form__field-reset;

    &:focus {
        outline: none;
        border-color: var(--color-black);
    }

    &::placeholder {
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        color: var(--color-blue--light);
    }

    &.-no-style {
        padding: 0;
        border: 0;
    }

    &.-textarea {
        resize: vertical;
    }

    &[readonly="readonly"],
    &.-read-only {
        display: block;
        border-radius: .4rem;
        pointer-events: none;
        background: var(--color-gray--dark_20);
        border: .2rem solid var(--color-gray--dark_20);

        font-family: var(--font-roboto);
        font-weight: var(--font-weight--bold);
        font-size: 1.6rem;
        line-height: 1.8;
        color: var(--color-blue);
    }

    &.-select {
        padding: 1.4rem 5rem 1.4rem 1.5rem;
        cursor: pointer;

        background-image: url(arrow.svg);
        background-repeat: no-repeat;
        background-size: 1.3rem;
        background-position: right 2rem center;

        @-moz-document url-prefix() { // fix text indentation in FF in select
            text-indent: -.3rem;
        }
    }

    &.-file {
        display: none;
    }

    &[type="password"]:focus {
        color: var(--color-blue--light);
        background-color: var(--color-gray-3);
        border-color: var(--color-gray--dark);
    }

    &[disabled] {
        background: var(--color-gray_10);
        opacity: .5;
    }

    .form__entry.-invalid & {
        border-color: var(--color-red);
        color: var(--color-red);

        // Firefox outline style & shadow
        outline: none;
        box-shadow: none;
    }

    .form__entry.-password-strength & {
        border-radius: 1rem 1rem 0 0;
    }

    &.-has-value,
    &[data-component='Form/togglePassword']:focus, // this one has 'hasValue' inside it
    &[data-component='Form/textarea']:focus, // this one has 'hasValue' inside it
    &[data-component='Form/hasValue']:focus,
    &[type=date] {
        $shift: 1rem;
        padding-top: (1.4rem + $shift);
        padding-bottom: (1.4rem - $shift);

        & ~ .form__label {
            font-size: 1.4rem;
            font-weight: var(--font-weight--regular);
            transform: translateY(1rem);
        }
    }
}

