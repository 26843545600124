.block {
    &.-columned {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &.-labeled-image {
        display: flex;
        align-items: center;
        margin: 0 0 4.7rem;

        font-family: var(--font-roboto);
        font-weight: var(--font-weight--regular);
        font-size: 1.7rem;
        line-height: 1.7;
        color: var(--color-blue--light);

        img {
            margin-right: 1.4rem;
        }
    }

    &.-grayed-and-center-text {
        transition: transform .3s ease, box-shadow .3s ease;
        transform: translateY(0);

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        max-width: 26rem;
        min-height: 26rem;
        margin: 0 0 5rem 5rem;
        padding: 1rem;

        font-family: var(--font-roboto);
        font-weight: var(--font-weight--medium);
        font-size: 1.5rem;
        line-height: 1.2;
        color: var(--color-blue--dark);
        text-align: center;

        border-radius: .4rem;
        background: var(--color-gray);

        cursor: pointer;

        &:hover,
        &:focus {
            box-shadow:
                0 3rem 2.4rem 0 rgba(0, 0, 0, .05),
                0 2rem 3rem 0 rgba(0, 0, 0, .05),
                0 1rem .5rem 0 rgba(0, 0, 0, .01)
        ;
            transform: translateY(-2rem);
        }
    }

    &.-small {
        margin-left: auto;
        margin-right: auto;
        padding: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &.-approved {
        background-color: whitesmoke;
        color: var(--color-corent);
    }

    &.-disapproved {
        background-color: whitesmoke;
        color: var(--color-red);
    }
}
