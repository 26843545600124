@import '../../node_modules/slim-select/dist/slimselect';

.select {
    z-index: 10;
    //width: 100%;

    .placeholder {
        display: block;
        padding-right: 1.5rem;

        color: var(--color-blue--light);
        font-weight: var(--font-weight--medium);
        font-size: var(--font-size--small);
        font-family: var(--font-roboto);
        line-height: 1.8;
    }

    &.-workgroups {
        //max-width: 21.3rem;
    }

    &.-partnerships {
        //max-width: 17rem;
    }

    &.-years {
        //max-width: 12.7rem;
    }

    &.-active {
        z-index: 9999;
    }

    @media (--query-mini-medium) {
        margin-bottom: 1rem;
    }
}

select.select {
    display: none;
}

.select__option {
    color: var(--color-blue--light);
    font-weight: var(--font-weight--medium);
    font-size: var(--font-size--small);
    font-family: var(--font-roboto);
    line-height: 1.7;

    &.-optgroup {
        text-transform: uppercase;
        font-family: var(--font-roboto);
        font-weight: var(--font-weight--regular);
        font-size: 1rem;
        line-height: 2;
        color: var(--color-black-2) !important;// to overwrite plugin style
    }
}

// Library overrides
.ss-main {
    //width: auto;

    .ss-single-selected {
        align-items: center;
        height: auto;
        padding: 1rem;
        background-color: transparent;
        //border: .1rem solid transparent;

        &.ss-open-below {
            background-color: var(--color-white);
            border-color: #DCDEE2;
            border-bottom: 0;
            border-top-left-radius: .8rem;
            border-top-right-radius: .8rem;
        }

        .ss-arrow {
            span {
                border: solid var(--color-blue);
                border-width: 0 .2rem .2rem 0;
            }
        }
    }

    .ss-content.ss-list.ss-option {
        &:hover {
            color: var(--color-blue);
            text-decoration: underline;
            background-color: var(--color-white);
        }

        &.ss-disabled {
            color: #DEDEDE;
            text-decoration: none;
            background-color: var(--color-white);
            cursor: not-allowed;
        }
    }

    &:not(:last-child) {
        margin-right: 7rem;
    }
}

.ss-content {
    &.ss-open {
        border: .1rem solid #DCDEE2;
        border-top: 0;
        border-bottom-right-radius: .8rem;
        border-bottom-left-radius: .8rem;
        // width: auto;
    }
}
