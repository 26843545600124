.forum-head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 55.8rem;
    margin: 0 auto 6rem auto;
}

.forum-head__img {
    display: block;
    margin: 0 1.5rem 0 0;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 100%;
}

.forum-head__text {
    display: block;
    margin: 0 3.3rem 0 0;
    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: 1.7rem;
    line-height: (29/17);
    color: var(--color-blue-3);
}
