.page-links-holder {
    margin-bottom: 7rem;
}

.page-links-holder__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: normal;
    justify-content: flex-start;
    margin: 0 -2.5rem;

    /*@media (--screen-large) {
        flex-wrap: nowrap;
    }*/
}
