/* ==========================================================================
    Fonts

    This file is exclusively intended for setting up variables
    Never add styles directly to this file
    ========================================================================== */

:root {
    --font-quicksand: 'Quicksand', sans-serif;
    --font-roboto: 'Roboto', sans-serif;

    --font-weight--regular: 400;
    --font-weight--medium: 500;
    --font-weight--bold: 700;

    --font-size--tiny: 1.1rem;
    --font-size--smallest: 1.3rem;
    --font-size--extra-small: 1.4rem;
    --font-size--smaller: 1.5rem;
    --font-size--small: 1.6rem;
    --font-size: 1.7rem;
    --font-size--medium: 1.8rem;
    --font-size--larger: 2rem;
    --font-size--large: 2.4rem;
    --font-size--extra-large: 2.5rem;
    --font-size--largest: 3rem;

    --font-size--h1: 5rem;
    --font-size--h1-mobile: 3.5rem;
    --font-size--h2: 3.5rem;
    --font-size--h2-mobile: 3rem;
    --font-size--h3: 2.8rem;
    --font-size--h3-tablet: 2.5rem;
    --font-size--h3-mobile: 2.2rem;
    --font-size--h4: 2.8rem;
    --font-size--h5: 1.6rem;
}

// had to use import, converted quicksand font files had a bug inside the letters making them render all wrong and weird
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap');

//@font-face {
//    font-family: 'Roboto';
//    src: url('fonts/Roboto-Bold.eot');
//    src: url('fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
//    url('fonts/Roboto-Bold.woff2') format('woff2'),
//    url('fonts/Roboto-Bold.woff') format('woff'),
//    url('fonts/Roboto-Bold.ttf') format('truetype'),
//    url('fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
//    font-weight: bold;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Roboto';
//    src: url('fonts/Roboto-Light.eot');
//    src: url('fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
//    url('fonts/Roboto-Light.woff2') format('woff2'),
//    url('fonts/Roboto-Light.woff') format('woff'),
//    url('fonts/Roboto-Light.ttf') format('truetype'),
//    url('fonts/Roboto-Light.svg#Roboto-Light') format('svg');
//    font-weight: 300;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Roboto';
//    src: url('fonts/Roboto-MediumItalic.eot');
//    src: url('fonts/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
//    url('fonts/Roboto-MediumItalic.woff2') format('woff2'),
//    url('fonts/Roboto-MediumItalic.woff') format('woff'),
//    url('fonts/Roboto-MediumItalic.ttf') format('truetype'),
//    url('fonts/Roboto-MediumItalic.svg#Roboto-MediumItalic') format('svg');
//    font-weight: 500;
//    font-style: italic;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Roboto';
//    src: url('fonts/Roboto-ThinItalic.eot');
//    src: url('fonts/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
//    url('fonts/Roboto-ThinItalic.woff2') format('woff2'),
//    url('fonts/Roboto-ThinItalic.woff') format('woff'),
//    url('fonts/Roboto-ThinItalic.ttf') format('truetype'),
//    url('fonts/Roboto-ThinItalic.svg#Roboto-ThinItalic') format('svg');
//    font-weight: 100;
//    font-style: italic;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Quicksand';
//    src: url('fonts/Quicksand-Light.eot');
//    src: url('fonts/Quicksand-Light.eot?#iefix') format('embedded-opentype'),
//    url('fonts/Quicksand-Light.woff2') format('woff2'),
//    url('fonts/Quicksand-Light.woff') format('woff'),
//    url('fonts/Quicksand-Light.ttf') format('truetype'),
//    url('fonts/Quicksand-Light.svg#Quicksand-Light') format('svg');
//    font-weight: 300;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Roboto';
//    src: url('fonts/Roboto-Medium.eot');
//    src: url('fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
//    url('fonts/Roboto-Medium.woff2') format('woff2'),
//    url('fonts/Roboto-Medium.woff') format('woff'),
//    url('fonts/Roboto-Medium.ttf') format('truetype'),
//    url('fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
//    font-weight: 500;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Quicksand';
//    src: url('fonts/Quicksand-Regular.eot');
//    src: url('fonts/Quicksand-Regular.eot?#iefix') format('embedded-opentype'),
//    url('fonts/Quicksand-Regular.woff2') format('woff2'),
//    url('fonts/Quicksand-Regular.woff') format('woff'),
//    url('fonts/Quicksand-Regular.ttf') format('truetype'),
//    url('fonts/Quicksand-Regular.svg#Quicksand-Regular') format('svg');
//    font-weight: normal;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Roboto';
//    src: url('fonts/Roboto-LightItalic.eot');
//    src: url('fonts/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
//    url('fonts/Roboto-LightItalic.woff2') format('woff2'),
//    url('fonts/Roboto-LightItalic.woff') format('woff'),
//    url('fonts/Roboto-LightItalic.ttf') format('truetype'),
//    url('fonts/Roboto-LightItalic.svg#Roboto-LightItalic') format('svg');
//    font-weight: 300;
//    font-style: italic;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Roboto';
//    src: url('fonts/Roboto-Thin.eot');
//    src: url('fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
//    url('fonts/Roboto-Thin.woff2') format('woff2'),
//    url('fonts/Roboto-Thin.woff') format('woff'),
//    url('fonts/Roboto-Thin.ttf') format('truetype'),
//    url('fonts/Roboto-Thin.svg#Roboto-Thin') format('svg');
//    font-weight: 100;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Quicksand';
//    src: url('fonts/Quicksand-Medium.eot');
//    src: url('fonts/Quicksand-Medium.eot?#iefix') format('embedded-opentype'),
//    url('fonts/quicksand-500.woff2') format('woff2'),
//    url('fonts/Quicksand-Medium.woff') format('woff'),
//    url('fonts/Quicksand-Medium.ttf') format('truetype'),
//    url('fonts/Quicksand-Medium.svg#Quicksand-Medium') format('svg');
//    font-weight: 500;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Roboto';
//    src: url('fonts/Roboto-BlackItalic.eot');
//    src: url('fonts/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
//    url('fonts/Roboto-BlackItalic.woff2') format('woff2'),
//    url('fonts/Roboto-BlackItalic.woff') format('woff'),
//    url('fonts/Roboto-BlackItalic.ttf') format('truetype'),
//    url('fonts/Roboto-BlackItalic.svg#Roboto-BlackItalic') format('svg');
//    font-weight: 900;
//    font-style: italic;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Roboto';
//    src: url('fonts/Roboto-Black.eot');
//    src: url('fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
//    url('fonts/Roboto-Black.woff2') format('woff2'),
//    url('fonts/Roboto-Black.woff') format('woff'),
//    url('fonts/Roboto-Black.ttf') format('truetype'),
//    url('fonts/Roboto-Black.svg#Roboto-Black') format('svg');
//    font-weight: 900;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Roboto';
//    src: url('fonts/Roboto-BoldItalic.eot');
//    src: url('fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
//    url('fonts/Roboto-BoldItalic.woff2') format('woff2'),
//    url('fonts/Roboto-BoldItalic.woff') format('woff'),
//    url('fonts/Roboto-BoldItalic.ttf') format('truetype'),
//    url('fonts/Roboto-BoldItalic.svg#Roboto-BoldItalic') format('svg');
//    font-weight: bold;
//    font-style: italic;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Quicksand';
//    src: url('fonts/Quicksand-SemiBold.eot');
//    src: url('fonts/Quicksand-SemiBold.eot?#iefix') format('embedded-opentype'),
//    url('fonts/Quicksand-SemiBold.woff2') format('woff2'),
//    url('fonts/Quicksand-SemiBold.woff') format('woff'),
//    url('fonts/Quicksand-SemiBold.ttf') format('truetype'),
//    url('fonts/Quicksand-SemiBold.svg#Quicksand-SemiBold') format('svg');
//    font-weight: 600;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Quicksand';
//    src: url('fonts/Quicksand-Bold.eot');
//    src: url('fonts/Quicksand-Bold.eot?#iefix') format('embedded-opentype'),
//    url('fonts/Quicksand-Bold.woff2') format('woff2'),
//    url('fonts/Quicksand-Bold.woff') format('woff'),
//    url('fonts/Quicksand-Bold.ttf') format('truetype'),
//    url('fonts/Quicksand-Bold.svg#Quicksand-Bold') format('svg');
//    font-weight: bold;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Roboto';
//    src: url('fonts/Roboto-Regular.eot');
//    src: url('fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
//    url('fonts/Roboto-Regular.woff2') format('woff2'),
//    url('fonts/Roboto-Regular.woff') format('woff'),
//    url('fonts/Roboto-Regular.ttf') format('truetype'),
//    url('fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
//    font-weight: normal;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Roboto';
//    src: url('fonts/Roboto-Italic.eot');
//    src: url('fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
//    url('fonts/Roboto-Italic.woff2') format('woff2'),
//    url('fonts/Roboto-Italic.woff') format('woff'),
//    url('fonts/Roboto-Italic.ttf') format('truetype'),
//    url('fonts/Roboto-Italic.svg#Roboto-Italic') format('svg');
//    font-weight: normal;
//    font-style: italic;
//    font-display: swap;
//}
