.search-results-separator {
    display: block;
    width: 100%;
    margin-bottom: 4rem;
    padding-bottom: 1rem;
    border-bottom: .1rem solid var(--color-gray--dark);
}

.search-results-separator__title {
    display: block;
    margin: 0 0 .5rem;

    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--regular);
    font-size: 2.2rem;
    line-height: 1.3;
    color: var(--color-gray--darkest);

    strong {
        display: inline-block;
        font: inherit;
        font-weight: var(--font-weight--bold);
    }

    a {
        display: inline-block;
        font: inherit;
        color: inherit;
        text-decoration: none;
    }
}

.search-results-separator__text {
    display: inline-block;
    margin: 0;
}

.search-results-separator__meta {
    display: block;

    font-family: var(--font-roboto);
    font-weight: var(--font-weight--bold);
    font-size: 1.5rem;
    line-height: 1rem;
    color: var(--color-blue--light);
    text-transform: uppercase;

    &::before {
        content: '';
        transition: background .3s ease;

        display: inline-block;
        width: 1rem;
        height: 1.5rem;
        margin-right: 1rem;

        background: currentColor;
        mask-image: url('../Images/svg/icon-pin.svg');
        mask-repeat: no-repeat;
        mask-size: 1rem 1.5rem;
    }
}
