.form-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2.5rem;

    &.-box {
        position: relative;
        background-color: var(--color-white);
        border: .2rem solid var(--color-gray--dark);
        border-radius: .4rem;
    }

    .search-bar & {
        //width: calc(100% - 2rem);

        @media (--screen-medium) {
            margin-bottom: 0;

            &:last-of-type {
                width: 32%;
            }
        }

        @media (--screen-large) {
            margin-bottom: 0;

            &:last-of-type {
                width: 50%;
            }
        }
    }
}

.form-control__inner {
    width: 100%;

    .-underlined & {
        position: relative;
    }

    .search-bar & {
        @media (--screen-medium) {
            margin-right: 2rem;
        }

        @media (--screen-large) {
            margin-right: 4rem;
        }
    }
}

.form-control__label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    font-size: var(--font-size--small);
    font-family: var(--font-roboto);
    white-space: nowrap;
    text-overflow: ellipsis;
    transition:
        font-size .3s ease,
        font-weight .3s ease,
        transform .3s ease;

    .-box & {
        display: flex;
        align-items: center;

        height: 100%;
        padding-left: 1.2rem;
        color: var(--color-blue--light);
        font-weight: var(--font-weight--bold);
    }

    .-underlined & {
        color: var(--color-gray--light);
        font-weight: var(--font-weight--regular);
        pointer-events: none;

        .-white & {
            color: var(--color-blue);
        }
    }
}

.form-control__input {
    width: 100%;
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--small);
    font-family: var(--font-roboto);
    line-height: 1.2;
    background-color: transparent;
    border: 0;
    outline: 0;
    transition: background-color .3s ease;

    .-box & {
        padding: 2.9rem 1.2rem .8rem;
        color: var(--color-blue--light);

        &:not(:placeholder-shown),
        &:focus {
            background-color: var(--color-gray--lightest);

            + .form-control__label {
                font-weight: var(--font-size--regular);
                transform: translateY(-13px);
                pointer-events: none;
            }
        }

        &.-icon-after {
            padding-right: 5rem;
        }

        &.-icon-before {
            padding-left: 5rem;
        }
    }

    .-underlined & {
        color: var(--color-gray--light);
        border-bottom: .1rem solid var(--color-gray--light);

        .-white & {
            color: var(--color-blue);
            border-bottom: .1rem solid var(--color-blue);
        }
    }

    &:not(:placeholder-shown),
    &:focus {
        + .form-control__label {
            font-size: var(--font-size--extra-small);
            transform: translateY(-125%);
        }
    }
}

.form-control__icon-before {
    flex: 1 0 2rem;
    margin-right: 1.8rem;
}

.form-control__toggle-password {
    position: absolute;
    right: 0;

    display: block;
    width: 2.5rem;
    height: 1.5rem;
    margin: 0;
    padding: 2rem;
    background: none;
    border: 0;
    font: 0/0 sans-serif;

    &::before,
    &::after {
        content: '';

        transition: opacity .3s ease;
        position: absolute;
        top: 0;
        left: 0;

        display: block;
        width: 100%;
        height: 100%;
        background-color: transparent;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 2.2rem;
    }

    &::before {
        background-image: url('../Images/svg/icon-visibility.svg');
        opacity: 1;
    }

    &::after {
        background-image: url('../Images/svg/icon-visibility-off.svg');
        opacity: 0;
    }

    &.-active {
        &::before {
            opacity: 0;
        }

        &::after {
            opacity: 1;
        }
    }
}

.form-control__icon-after {
    .-box & {
        position: absolute;
        top: 50%;
        right: 1.2rem;
        max-width: 2.2rem;
        transform: translateY(-50%);
    }
}

.form-control__submit {
    @extend .button;

    /*.-white & {
        @extend .-white;
    }*/

    .search-bar & {
        width: 100%;
        font-size: var(--font-size);

        @media (--screen-medium) {
            width: auto;
            padding: 1rem 2rem;
        }

        @media (--screen-large) {
            padding: 1.7rem 2.6rem;
        }

        &:hover,
        &:focus {
            background-color: var(--color-blue--dark);
        }
    }
}

.form-control__autocomplete {
    .autocomplete-suggestion {
        font-weight: var(--font-weight--regular);
        font-size: var(--font-size--smaller);
        font-family: var(--font-roboto);
    }
}

.strength-meter {
    position: absolute;
    bottom: -.3rem;
    left: 0;
    width: 100%;
    height: .4rem;
    border-radius: .35rem;
    transition:
        background-color .3s ease,
        width .3s ease;

    &::before {
        position: absolute;
        right: 0;
        bottom: -1.4rem;
        font-weight: var(--font-weight--regular);
        font-size: var(--font-size--tiny);
        font-family: var(--font-roboto);
        text-transform: uppercase;
        background-color: transparent;
        content: '';
    }

    &.-error {
        width: 100%;
        color: var(--color-alert--error);
        background-color: var(--color-alert--error);

        &::before {
            content: 'Onvoldoende karakters';
        }
    }

    &.-very-weak {
        width: calc(100% / 5);
        color: var(--color-alert--error);
        background-color: var(--color-alert--error);

        &::before {
            content: 'zeer zwak';
        }
    }

    &.-weak {
        width: calc((100% / 5) * 2);
        color: var(--color-alert--error);
        background-color: var(--color-alert--error);

        &::before {
            content: 'zwak';
        }
    }

    &.-good {
        width: calc((100% / 5) * 3);
        color: var(--color-alert--warning);
        background-color: var(--color-alert--warning);

        &::before {
            content: 'goed';
        }
    }

    &.-strong {
        width: calc((100% / 5) * 4);
        color: var(--color-alert--success);
        background-color: var(--color-alert--success);

        &::before {
            content: 'sterk';
        }
    }

    &.-very-strong {
        width: 100%;
        color: var(--color-alert--success);
        background-color: var(--color-alert--success);

        &::before {
            content: 'zeer sterk';
        }
    }
}
