.content__head-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content {
    @include content-theme();

    margin-bottom: 5rem;

    .-medizorg & {
        @include content-theme(medizorg);
    }

    .-covent & {
        @include content-theme(covent);
    }

    .-corent & {
        @include content-theme(corent);
    }

    .-covimmo & {
        @include content-theme(covimmo);
    }

    .-mz-consult & {
        @include content-theme(mz-consult);
    }

    .-personalia & {
        @include content-theme(personalia);
    }

    .-algemeen & {
        @include content-theme(algemeen);
    }

    p,
    li,
    i,
    a,
    strong,
    tr,
    th {
        color: var(--color-blue--light);
        font-weight: var(--font-weight--regular);
        font-size: var(--font-size);
        font-family: var(--font-roboto);
    }

    th {
        vertical-align: top;
    }

    strong {
        font-weight: var(--font-weight--bold);
    }

    ul,
    ol {
        padding-left: 2rem;
    }

    p {
        line-height: 1.7;
    }
}
