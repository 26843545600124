.report-attachment-holder {
    width: 100%;
    padding: 3.5rem 0 7rem;
    background-color: var(--color-gray);
}

.report-attachment-holder__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.report-attachment-holder__title {
    color: var(--color-blue);
    text-align: center;
}
