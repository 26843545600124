.section-heading {
    position: relative;
    z-index: 1;

    box-sizing: border-box;
    display: block;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 3rem;

    @media (--query-medium-extra-large) {
        margin-bottom: 6.4rem;
    }

    &.-small {
        box-sizing: border-box;
        max-width: calc(61.1rem + 20rem); // includes padding

        @media (--query-medium-extra-large) {
            margin-bottom: 8rem;
            padding-left: 10rem;
            padding-right: 10rem;
        }
    }
}

.section-heading__back-link {
    transition: color .3s ease;

    position: relative;
    display: block;
    margin: 0;
    margin-bottom: 1.5rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2.2;
    color: var(--color-blue--light);
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;

    &::before {
        $w: 4.6rem;
        $h: 1.6rem;
        $icon: 'long-arrow.svg';

        content: '';
        transition: background .3s ease;

        position: relative;
        top: .2rem;

        display: inline-block;
        width: $w;
        height: $h;
        margin-right: 1rem;

        background: url($icon) center center no-repeat;
        background-size: $w $h;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url($icon);
            mask-repeat: no-repeat;
            mask-size: $w $h;
        }
    }

    &:hover,
    &:focus {
        text-decoration: none;
        color: var(--color-black);
    }
}

.section-heading__title {
    display: block;
    margin: 0 auto;
    margin-bottom: 3rem;
    max-width: 71.4rem;
    color: var(--color-blue);
    text-align: center;

    .section-heading.-small & {
        margin-bottom: .5rem;
        font-size: 3rem;
        line-height: (35/30);
        text-align: left;
    }
}

.section-heading__sub-title {
    display: block;
    margin: 0;
    margin-bottom: 1.6rem;
    text-align: center;
}

.section-heading__description {
    display: block;
    margin: 0;
    margin-bottom: 3rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.65;
    color: var(--color-gray_50);
    text-align: center;

    .section-heading.-small & {
        font-size: 1.7rem;
        line-height: 1.58;
    }
}

.section-heading__meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;

    &.-spread-out {
        justify-content: space-evenly;
    }

    .section-heading.-small & {
        justify-content: flex-start;
    }
}

.section-heading__meta-item {
    position: relative;
    display: block;
    margin: 0 1rem 1.5rem 1rem;
    padding-left: 2.2rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.26;
    color: var(--color-gray_50);

    &::before {
        content: '';

        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        transition: background .3s ease;
        display: block;
        background: currentColor;
        mask-repeat: no-repeat;
    }

    &.-date {
        padding-left: (1.6rem + .8rem);

        &::before {
            width: 1.6rem;
            height: 1.8rem;
            mask-image: url(calendar.svg);
            mask-size: 1.6rem 1.8rem;
        }
    }

    &.-time {
        padding-left: (1.8rem + .8rem);

        &::before {
            width: 1.8rem;
            height: 1.8rem;
            mask-image: url(clock.svg);
            mask-size: 1.8rem 1.8rem;
        }
    }

    &.-price {
        padding-left: (1.8rem + .8rem);

        &::before {
            width: 1.8rem;
            height: 1.8rem;
            mask-image: url(circled-euro-symbol.svg);
            mask-size: 1.8rem 1.8rem;
        }
    }

    &.-location {
        padding-left: (2.2rem + .8rem);

        &::before {
            width: 2.2rem;
            height: 2.2rem;
            mask-image: url(circled-pin.svg);
            mask-size: 2.2rem 2.2rem;
        }
    }

    &.-category {
        text-transform: uppercase;
    }

    &.-avatar {
        display: flex;
        padding-left: 0;

        &::before {
            display: none;
        }
    }

    &.-reaction {
        &::before {
            $w: 1.2rem;
            $h: 1.2rem;
            $icon: 'speech-bubble.svg';

            content: '';
            transition: background .3s ease;

            display: inline-block;
            vertical-align: middle;
            width: $w;
            height: $h;
            margin-right: .9rem;

            background: url($icon) center center no-repeat;
            background-size: $w $h;

            @supports (mask-repeat: no-repeat) {
                background: currentColor;
                mask-image: url($icon);
                mask-repeat: no-repeat;
                mask-size: $w $h;
            }
        }
    }

    &[class*="-tag-"] {
        span {
            display: block;
            padding: 0 1.2rem;
            border-radius: 1.1rem;
            font-family: var(--font-roboto);
            font-weight: var(--font-weight--medium);
            font-size: 1.5rem;
            line-height: 2.2rem;
            color: var(--color-white);
            text-transform: capitalize;
        }
    }

    &.-tag-blue span,
    &.-tag-medizorg span {
        background-color: var(--color-medizorg);
    }

    &.-tag-green span,
    &.-tag-corent span {
        background-color: var(--color-corent);
    }

    &.-tag-pink span,
    &.-tag-covent span {
        background-color: var(--color-covent);
    }

    &.-tag-orange span,
    &.-tag-covimmo span {
        background-color: var(--color-covimmo);
    }

    &.-tag-purple span,
    &.-tag-ms-consult span {
        background-color: var(--color-mz-consult);
    }

    &.-tag-grey span,
    &.-tag-algemeen span {
        background-color: var(--color-algemeen);
    }

    &.-tag-lightgrey span,
    &.-tag-personalia span {
        background-color: var(--color-blue);
    }

    .section-heading.-small & {
        &:first-child {
            margin: 0 2rem 1.5rem 0;
        }
    }
}

.section-heading__introduction {
    text-align: center;
    max-width: 71.4rem;
    margin-left: auto;
    margin-right: auto;
}
