.group-list__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 108.2rem;
    margin: 0 auto;

    @media (--screen-medium) {
        padding-left: 4.5rem;
    }
}

.group-list__groups {
    margin-top: 1.5rem;
}

.group-list__link {
    color: var(--color-blue);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--larger);
    font-family: var(--font-roboto);

    &:not(:last-child) {
        margin-right: 1.2rem;
    }
}

.group-list__label {
    color: var(--color-gray-darkest);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--large);
    font-family: var(--font-roboto);
}
