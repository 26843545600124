.back-link {
    display: block;
    width: 100%;
    margin: 0 auto;
    text-decoration: none;
    text-align: center;

    &.-left-aligned {
        text-align: left;
        margin-bottom: 3rem;
    }
}

.back-link__arrow {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 4.6rem;
    height: .2rem;
    background-color: var(--color-blue--light);

    &::before,
    &::after {
        position: absolute;
        top: 50%;
        left: -.1rem;
        width: .9rem;
        height: .2rem;
        background-color: var(--color-blue--light);
        content: '';
    }

    &::before {
        top: auto;
        bottom: -.3rem;
        transform: rotate(45deg);
    }

    &::after {
        top: -.3rem;
        transform: rotate(-45deg);
    }
}

.back-link__label {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 2rem;
    color: var(--color-blue--light);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--smaller);
    font-family: var(--font-roboto);
    text-transform: uppercase;
}
