.hero-image {
    display: block;
    width: auto;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;

    @media (--query-medium-extra-large) {
        margin-bottom: 5.8rem;
    }

    img {
        display: block;
        width: auto;
        max-width: 100%;
        margin: 0;
    }
}
