a {
    &.-no-style {
        font: inherit;
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.link {
    color: var(--color-gray--darkest);
    font-weight: var(--font-weight--medium);
    font-size: var(--font-size--small);
    font-family: var(--font-quicksand);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    .-footer & {
        color: var(--color-white);
        font-weight: var(--font-weight--bold);
        font-size: var(--font-size--small);
        line-height: 1.8;
    }

    .-footer--alt & {
        @extend .button;
        @extend .-dark-blue;
    }

    .-mobile-overlay-meta & {
        color: var(--color-white);
        font-weight: var(--font-weight--bold);
        font-size: 2.2rem;
        font-family: var(--font-quicksand);
    }

    .-mobile-overlay-main &,
    .-mobile-overlay-login & {
        color: var(--color-white);
        font-weight: var(--font-weight--medium);
        font-size: var(--font-size--small);
        font-family: var(--font-quicksand);
    }

    .-header-intranet-main &,
    .-header-intranet-top &,
    .-header-main & {
        position: relative;
        transition: color .3s ease;

        &::after {
            position: absolute;
            bottom: -.8rem;
            left: 0;
            width: 100%;
            height: .4rem;
            background-color: var(--color-pink);
            border-radius: .35rem;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform .3s ease;
            content: '';
        }

        &:hover,
        &.-active {
            text-decoration: none;

            &::after {
                transform: scaleX(1);
            }
        }

        &.-active {
            color: var(--color-pink);

            &::after {
                width: 2rem;
            }
        }
    }

    .-header-intranet-top & {
        color: var(--color-blue);
    }

    .-header-intranet-main & {
        display: flex;
        align-items: center;
        height: 100%;
        color: var(--color-blue);
        font-weight: var(--font-weight--bold);
        font-size: var(--font-size--extra-small);

        &::after {
            bottom: -.4rem;
            height: .7rem;
        }

        &:hover {
            color: var(--color-blue);
        }

        .link__icon-before,
        .link__icon-after {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    .-header-intranet-user & {
        display: inline-block;
        width: 100%;
        padding: 1.3rem 1.7rem;
        color: var(--color-blue--dark);
        font-size: var(--font-size--smaller);
        transition: background-color .3s ease;

        &:hover {
            text-decoration: none;
            background-color: var(--color-gray);
        }
    }

    .-header-meta & {
        @extend .button;
        @extend .-has-shadow;
        @extend .-white;

        font-family: var(--font-quicksand);
        font-weight: var(--font-weight--bold);
        font-size: 1.8rem;

        @media (--query-mini-small) {
            font-size: 1.6rem;
            padding: .9rem 1.2rem;
            font-weight: normal;
        }
    }

    .-copyright & {
        color: var(--color-white);
        font-weight: var(--font-weight--regular);
        font-size: var(--font-size--smaller);
        font-family: var(--font-roboto);
        line-height: 1.6;
    }

    .-copyright--alt & {
        color: var(--color-white);
        font-weight: var(--font-weight--regular);
        font-size: var(--font-size--smaller);
        font-family: var(--font-roboto);
        line-height: 1.6;
    }

    .link__icon-before {
        margin-right: .5rem;

        &.-lock {
            width: 1rem;
        }
    }

    .link__icon-after {
        margin-left: .5rem;
    }

    &.-remove {
        margin-bottom: 1rem;
        color: var(--color-red);
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &.-flex {
        display: inline-flex;
    }

    &.-login {
        position: relative;
        padding-left: 2rem;

        > img {
            position: absolute;
            top: calc(50% - .8rem);
            left: 0;
        }
    }
}


.link__icon-before {
    .navigation__item.-login & {
        display: inline-block;
        max-width: 1rem;
        position: relative;
        top: -.2rem;
    }
}
