.search-bar {
    width: 100%;
    padding: 2.4rem;
    border-radius: 3rem;

    &.-blue {
        background-color: var(--color-blue);
    }

    &.-white {
        background-color: var(--color-white);
        box-shadow: rgba(0, 0, 0, .1) 0 .2rem .5rem .2rem;
    }

    &.-large-shadow {
        box-shadow: 0 1rem 2.4rem 0 rgba(0, 0, 0, .1);
    }

    &.-animated {
        animation: shadow-pulse .5s 2;
    }

    .-intranet & {
        display: none;

        @media (--screen-medium) {
            display: inline-block;
        }
    }

    // needed to overwrite .-intranet & {...}
    // Sorry, There's no time to fix thas shit
    &.-always-visible {
        display: block;
    }

    .-top & {
        width: 100%;
        max-width: 82.9rem;
        margin: 3.1rem;
    }

    &.-mobile-pulled-up {
        @media (--query-mini-medium) {
            margin-top: -5rem;
        }
    }

    @media (--screen-large) {
        padding: 1.8rem 2.4rem 1.8rem 4.5rem;
        border-radius: 5rem;
    }
}

@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, .2);
    }

    100% {
        box-shadow: 0 0 0 3.5rem rgba(0, 0, 0, 0);
    }
}
