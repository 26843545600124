.user-navigation {
    position: relative;
    z-index: 1;
    display: none;
    padding: 2.2rem 1.8rem 1.8rem;
    background-color: rgba(255, 255, 255, .3);
    /*border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;*/
    border-radius: 1.5rem;

    cursor: pointer;

    @media (--screen-medium) {
        display: inline-block;
    }

    @media screen and (min-width: 1035px) {
        margin-top: -1.5rem;
        padding-top: 2.2rem + 1.5rem;
    }
}

.user-navigation__info {
    display: flex;
    flex-direction: row;
}

.user-navigation__image {
    width: 3.1rem;
    height: 3.1rem;
    margin-right: 1.1rem;
    border-radius: 100%;
}

.user-navigation__text {
    display: flex;
    flex-direction: column;
}

.user-navigation__logged-in {
    margin-bottom: .5rem;
    color: var(--color-blue);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--tiny);
    font-family: var(--font-roboto);
    text-transform: uppercase;
}

.user-navigation__name {
    position: relative;
    color: var(--color-blue);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size);
    font-family: var(--font-quicksand);

    &::after {
        $w: 1.3rem;
        $h: .8rem;
        $icon: 'arrow.svg';

        content: '';
        transition: background .3s ease;

        position: relative;
        top: -.2rem;

        display: inline-block;
        width: $w;
        height: $h;
        margin-left: .5rem;

        background: url($icon) center center no-repeat;
        background-size: $w $h;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url($icon);
            mask-repeat: no-repeat;
            mask-size: $w $h;
        }
    }
}
