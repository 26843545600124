$gutter--desktop: 5rem;
$gutter--mobile: 1.1rem;

.container {
    width: 100%;
    max-width: 126.6rem + $gutter--mobile * 2;
    margin: 0 auto;
    padding: 0 $gutter--mobile;

    &.-small {
        max-width: 92.2rem + $gutter--mobile * 2;
    }

    &.-smallest {
        max-width: 61rem + $gutter--mobile * 2;
    }

    &.-content-head {
        max-width: 71rem + $gutter--mobile * 2;
    }

    &.-intranet {
        max-width: 123.3rem + $gutter--mobile * 2;
    }

    &.-intranet-content {
        max-width: 115rem + $gutter--mobile * 2;
    }

    &.-overview {
        max-width: 115rem + $gutter--mobile * 2;
    }

    &.-specialists-overview,
    &.-offices-overview {
        max-width: 121.5rem + $gutter--mobile * 2;
    }

    &.-full-height {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    @media (--screen-small) {
        max-width: 126.6rem + $gutter--desktop * 2;
        padding: 0 $gutter--desktop;

        &.-intranet {
            max-width: 123.3rem + $gutter--desktop * 2;
        }

        &.-intranet-content {
            max-width: 115rem + $gutter--desktop * 2;
        }

        &.-small {
            max-width: 92.2rem + $gutter--desktop * 2;
        }

        &.-smallest {
            max-width: 61rem + $gutter--desktop * 2;
        }

        &.-content-head {
            max-width: 71rem + $gutter--desktop * 2;
        }

        &.-overview {
            max-width: 107.2rem + $gutter--desktop * 2;
        }

        &.-specialists-overview,
        &.-offices-overview {
            max-width: 121.5rem + $gutter--desktop * 2;
        }
    }

    &.-maxed-83 {
        max-width: 83rem;
    }

    &.-approval {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        justify-content: center;
    }
}
