/* Image Gallery */
@import '~photoswipe/dist/photoswipe.css';
@import '~photoswipe/dist/default-skin/default-skin.css';

.image-gallery {
    display: block;
    box-sizing: border-box;
    width: 100%;

    max-width: 61.1rem;
    margin-right: auto;
    margin-bottom: 5rem;
    margin-left: auto;
    column-count: 2;
    column-gap: 2rem;

    @media (--query-medium-extra-large) {
        margin-bottom: 9rem;
        column-count: 3;
    }
}

.image-gallery__item {
    position: relative;
    display: block;
    width: 100%;
    max-width: 40rem;
    margin-bottom: 2rem;
    padding-bottom: 0;
    page-break-inside: avoid;

    -webkit-column-break-inside: avoid;
    break-inside: avoid;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        background: var(--color-black);
        background: color-mod(var(--color-blue) a(70%));
        opacity: 0;
        transition: opacity .3s ease;
        content: '';
        pointer-events: none;
    }

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        display: block;
        width: 3.8rem;
        height: 3.8rem;
        margin-top: 0;
        background: var(--color-white);
        transform: translate3d(-50%, -50%, 0);
        opacity: 0;
        transition: opacity .3s ease, background .3s ease;
        content: '';

        pointer-events: none;
        mask-image: url(zoom-magnifier.svg);
        mask-repeat: no-repeat;
        mask-size: 3.8rem 3.8rem;
    }

    &:hover,
    &:focus {
        &::before {
            opacity: 1;
        }

        &::after {
            opacity: 1;
        }

        .image-gallery__counter {
            color: var(--color-white);
        }
    }

    &.-count-box {
        position: relative;
        display: block;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        border: .1rem solid var(--color-gray--dark);

        img {
            display: none;
        }

        a {
            position: relative;
            z-index: 1;
            display: block;
            width: 100%;
            padding-top: 50%;
            padding-bottom: 50%;
            color: var(--color-blue--light);
            font-weight: bold;
            font-size: 3.3rem;

            font-family: var(--font-quicksand);
            line-height: 0;
            text-align: center;
            text-decoration: none;
        }

        &::before {
            display: none;
        }

        &::after {
            height: 100%;
        }
    }

    &.-hidden {
        height: 0;
        margin: 0;
        padding: 0;
        overflow: hidden;
        opacity: 0;
    }

    .figure,
    a,
    img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0;
        border-radius: .4rem;
        transition: transform .3s ease;
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        display: block;
        flex: 1 0 176px;
        width: 100%;
        max-width: 176px;

        a {
            display: block;
            padding-right: 1rem;
        }
    }
}

.image-gallery__counter {
    transition: color .3s ease;
    position: relative;
    z-index: 10;
}

.pswp {
    z-index: 9999;
}

.pswp__zoom-wrap {
    display: flex;
}
