.splitview {
    position: relative;
    width: 100%;

    &::before {
        position: absolute;
        bottom: 0;
        left: -5rem;
        width: 29rem;
        height: 43.5rem;
        background-image: url('../Images/red-rectangle-background.png');
        background-size: cover;
        content: '';

        @media (--screen-medium) {
            top: -9rem;
            left: -5rem;
            width: 67rem;
            height: 90rem;
        }
    }
}

.splitview__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 3.2rem 12rem;

    > * {
        z-index: 1;
    }

    &::before {
        position: absolute;
        bottom: -14rem;
        left: -3rem;
        width: 13rem;
        height: 24.2rem;
        background-image: url('../Images/girl.png');
        background-size: cover;
        content: '';
    }

    &::after {
        position: absolute;
        right: -5rem;
        bottom: 0;
        width: 9.7rem;
        height: 12.6rem;
        background-image: url('../Images/glass.png');
        background-size: cover;
        content: '';
    }

    @media (--screen-medium) {
        padding: 0 12.2rem 12rem;

        &::before {
            bottom: 0;
            left: -9rem;
            width: 20rem;
            height: 37.2rem;
        }

        &::after {
            right: -6rem;
            width: 20.7rem;
            height: 37.6rem;
        }
    }

    @media (--screen-large) {
        align-items: center;
        padding: 0 15.5rem 11.1rem;

        &::before {
            bottom: 0;
            left: -6rem;
            width: 20rem;
            height: 37.2rem;
        }

        &::after {
            right: -11rem;
            width: 24rem;
            height: 31.1rem;
        }
    }
}

.splitview__image {
    position: absolute;
    z-index: 0;

    &.-left {
        bottom: -14rem;
        left: -3rem;
        width: 13rem;

        @media (--screen-large) {
            bottom: 0;
            left: -6rem;
            width: 20rem;
        }
    }

    &.-right {
        right: -5rem;
        bottom: 0;
        width: 9.7rem;

        @media (--screen-large) {
            right: -10rem;
            width: 24rem;
        }
    }
}

.splitview__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (--screen-large) {
        flex-wrap: wrap;
        max-height: 300px; // To force breaking to second column;
    }
}

.splitview__title {
    flex: 0 1 auto;
    color: var(--color-blue--dark);
}

.splitview__first {
    width: 100%;
    margin-top: 0;
    color: var(--color-gray--darkest);
    line-height: 1.5;

    @media (--screen-large) {
        width: 50%;
    }
}

.splitview__cta {
    flex: 1 1 auto;
    order: 2;

    @media (--screen-large) {
        order: 0;
        width: 50%;
    }
}

.splitview__button {
    @extend .button;
}

.splitview__second {
    flex: 1 0 100%;
    width: 100%;
    margin-bottom: 3rem;
    padding: 0;
    color: var(--color-blue--light);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size);
    font-family: var(--font-roboto);
    line-height: 1.7;

    @media (--screen-large) {
        width: 50%;
        margin-bottom: 0;
        padding-left: 2rem;
    }
}
