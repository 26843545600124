.form__entry {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.6rem;

    &.-fixed-width {
        max-width: 51.7rem;
    }

    &.-row {
        flex-direction: row;
        align-items: center;
    }

    &.-full-width {
        width: 100%;
    }

    &.-invalid {
        // see form__field.scss
        // see form__message.scss
    }

    &.-password-strength {
        // see password-strength.scss
    }
}
