.page-link-entry {
    position: relative;
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 2.5rem;
    margin-bottom: 5rem;
    padding: 3.8rem;
    background-color: var(--color-white);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    min-width: 25rem;

    cursor: pointer;

    transition: transform .3s ease, box-shadow .3s ease;
    transform: translateY(0);

    &:hover,
    &:focus {
        box-shadow:
            0 3rem 2.4rem 0 rgba(0, 0, 0, .05),
            0 2rem 3rem 0 rgba(0, 0, 0, .05),
            0 1rem .5rem 0 rgba(0, 0, 0, .01)
        ;
        transform: translateY(-2rem);
    }

    @media (--screen-medium) {
        flex: 0 0 calc(50% - 5rem);
    }

    @media (--screen-large) {
        flex: 1 1 30rem;
    }

    &.-ghost {
        opacity: 0;
        height: 0;
        margin-bottom: 0;
        padding: 0;
    }

}

.page-link-entry__title {
    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--bold);
    font-size: 3rem;
    line-height: 1.3;
    color: var(--color-blue);
    margin: 0 0 4.8rem;
}
//
//.page-link-entry__link {
//    @extend .button;
//    @include theme-button();
//
//    .-medizorg & {
//        @include theme-button(medizorg);
//    }
//
//    .-covent & {
//        @include theme-button(covent);
//    }
//
//    .-corent & {
//        @include theme-button(corent);
//    }
//
//    .-covimmo & {
//        @include theme-button(covimmo);
//    }
//
//    .-mz-consult & {
//        @include theme-button(mz-consult);
//    }
//
//    .-personalia & {
//        @include theme-button(personalia);
//    }
//
//    .-algemeen & {
//        @include theme-button(algemeen);
//    }
//}
