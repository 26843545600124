.layout {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &.-half-columns {
        @media (--query-medium) {
            width: calc(100% + 2rem);
            margin-left: -2rem;
        }

        @media (--query-large-extra-large) {
            width: calc(100% + 9rem);
            margin-left: -9rem;
        }
    }
}

.layout__section {
    display: block;
    width: 100%;

    .layout.-two-columns & {
        max-width: 62rem;

        @media (--query-medium-extra-large) {
            flex-grow: 1;
            flex-basis: 40rem;
        }
    }

    &.-half-columns {
        @media (--query-medium) {
            max-width: calc(50% - 2rem);
            margin-left: 2rem;
        }

        @media (--query-large-extra-large) {
            max-width: calc(50% - 9rem);
            margin-left: 9rem;
        }
    }

    &.-full {
        max-width: 100%;
        flex-basis: 100%;

        .layout.-half-columns & {
            @media (--query-medium) {
                margin-left: 2rem;
            }

            @media (--query-large-extra-large) {
                margin-left: 9rem;
            }
        }
    }

    &.-maxed-89 {
        max-width: 89rem;
    }

    &.-fixed-width {
        max-width: 56.1rem;
        margin-right: 8.7rem;
    }
}
