.partnership {
    position: relative;

    display: block;
    width: 100%;
    margin-left: 2.5rem;
    margin-bottom: 4rem;

    transform-origin: center;
    transition: transform .3s ease;

    flex-grow: 1;
    flex-basis: 18rem;

    &:hover {
        transform: scale(1.1);
    }

    @media (--query-medium-extra-large) {
        flex-basis: 21.2rem;
    }

    &.-ghost {
        height: 0;
        opacity: 0;
        overflow: hidden;
    }
}

.partnership__background {
    width: 100%;
    height: 100%;
    object-fit: contain;

    @media (--query-mini-medium) {
        max-width: 22rem;
        margin: 0 auto;
        display: block;
    }
}

.partnership__info {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    text-align: center;
    transform: translate3d(-50%, -25%, 0);
}

.partnership__icon {
    position: relative;

    img {
        width: 1.6rem;
        height: auto;

        @media (--screen-small) {
            width: 2.4rem;
        }
    }

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        width: 3.8rem;
        height: 3.8rem;
        border-radius: 50%;
        transform: translate3d(-50%, -51%, 0);
        content: '';

        .-blue &,
        .-medizorg & {
            background-color: var(--color-medizorg);
        }

        .-green &,
        .-corent & {
            background-color: var(--color-corent);
        }

        .-pink &,
        .-covent & {
            background-color: var(--color-covent);
        }

        .-orange &,
        .-covimmo & {
            background-color: var(--color-covimmo);
        }

        .-purple &,
        .-mz-consult & {
            background-color: var(--color-mz-consult);
        }

        .-lightgrey &,
        .-personalia & {
            padding-left: .5rem;
            color: var(--color-blue--light);
            line-height: 0;
            background-color: var(--color-personalia);
        }

        .-grey &,
        .-algemeen & {
            background-color: var(--color-algemeen);
        }

        @media (--screen-small) {
            width: 6.3rem;
            height: 6.3rem;
        }
    }
}

.partnership__title {
    margin-top: 1.4rem;
    color: var(--color-gray--darkest);
    font-weight: var(--font-weight--medium);
    font-size: var(--font-size--smallest);
    font-family: var(--font-roboto);
    text-transform: uppercase;

    @media (--screen-small) {
        margin-top: 3rem;
    }
}
