.report-holder {
    margin-bottom: 10rem;
}

.report-holder__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.report-holder__title {
    color: var(--color-blue--dark);
}
