/* html */
html {
    box-sizing: border-box;
    width: 100%;
    min-width: 320px;
    margin: 0 auto;
    overflow-x: hidden;
    font-size: 62.5%; // base for rem units - for ±97% of all browsers this equates to 10px
    line-height: 1;

    scroll-behavior: smooth; // smooth scrolling to anchors

    background: var(--color-white); // set to overwrite Firefox themes
    touch-action: manipulation; // remove 350ms delay on ios

    * {
        box-sizing: border-box;
    }

    &.-popup-open {
        overflow-y: hidden;
    }
}
