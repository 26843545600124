blockquote,
.blockquote {
    position: relative;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 1rem 0;

    @media (--query-medium-extra-large) {
        max-width: 54.8rem;
        margin-left: auto;
        margin-right: auto;
    }

    p {
        display: block;
        margin: 0 0 3rem;

        font-family: var(--font-quicksand);
        font-weight: 300;
        font-size: 1.8rem;
        line-height: 1.3;
        color: var(--color-blue);

        @media (--query-medium-extra-large) {
            font-size: 2.5rem;
        }

        &:last-of-type {
            margin-bottom: 1rem;
        }
    }

    footer {
        display: block;
        margin: 0;
        padding: 0;

        font-family: var(--font-default);
        font-weight: bold;
        font-size: 2.8rem;
        line-height: 1.26;
        color: var(--color-blue--light);

        @media (--query-medium-extra-large) {
            font-size: 3.8rem;
        }

        &::before {
            content: '—';
            margin-right: 1ch;
        }
    }

    & > :last-child {
        margin-bottom: 0;
    }

    .ck-content & {
        background: var(--color-gray--lightest);

        overflow: visible;
        font-style: normal;
        border-left: none;
    }
}

.blockquote__author {
    font-size: 1.7rem;
    line-height: 1.8;
    color: var(--color-blue--light);
}
