.search-wrapper {
    position: relative;
    width: 100%;
    max-width: 108.2rem;
    margin: 0 auto;

    .-page-home & {
        margin: 0 auto 3rem;

        @media (--screen-medium) {
            margin: 0 auto 23rem;
        }
    }

    .-page-intranet & {
        flex: 1 1 auto;
        max-width: 82.9rem;
        margin: 0;
        transform: translateY(50%);
    }

    &.-pulled-down,
    .-page-specialists-overview &,
    .-page-intranet-specialists-overview &,
    .-page-offices-overview & {
        transform: translateY(50%);
    }

    &.-top {
        display: flex;
        margin-top: -20rem;
        background-color: var(--color-blue--dark);
        transform: none;
        transition: margin-top .3s ease;

        @media (--screen-medium) {
            display: none;
        }
    }
}

.search-wrapper__image {
    position: absolute;
    right: -3.7rem;
    bottom: -11.4rem;
    z-index: -1;
    width: 17rem;

    @media (--screen-medium) {
        top: 50%;
        right: -31rem;
        bottom: 0;
        width: auto;
        transform: translate3d(0, -100%, 0);
    }
}
