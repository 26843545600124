.footer--alt {
    z-index: 1;
    background-color: var(--color-blue);
}

.footer--alt__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4.2rem 0;

    @media (--screen-extra-large) {
        flex-direction: row;
        justify-content: flex-start;
    }
}

.footer--alt__logo {
    width: 19.8rem;
    margin-bottom: 2rem;

    @media (--screen-extra-large) {
        margin-bottom: 0;
    }
}
