.report-entry {
    @include white-box();

    display: block;
    margin-bottom: 1.4rem;
    padding: 2.8rem 4.1rem;
    text-decoration: none;

    &:last-of-type {
        margin-bottom: 2.5rem;
    }

    @media (--screen-extra-large) {
        &:hover {
            .report-entry__button {
                opacity: 1;
            }
        }
    }
}

a.report-entry {
    @extend .-animated;
}

.report-entry__inner {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    transition: transform .3s ease;

    @media (--screen-medium) {
        align-items: center;
    }
}

.report-entry__info {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.report-entry__file-image {
    width: 3.6rem;
    padding: 0 1.6rem 0 0;

    @media (--screen-medium) {
        width: auto;
        padding: 0 4rem 0 1.3rem;
    }
}

.report-entry__date {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0 1.6rem 0 0;
    color: var(--color-blue--light);
    font-weight: var(--font-weight--medium);
    font-size: var(--font-size--smallest);
    font-family: var(--font-roboto);
    line-height: 1;

    @media (--query-mini-small) {
        margin-bottom: .5rem;
    }

    @media (--screen-small) {
        font-size: var(--font-size--smaller);
    }
}

.report-entry__meta {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    order: 0;

    .tag {
        margin-right: 1.6rem;

        @media (--query-mini-small) {
            margin-bottom: .5rem;
        }
    }

    @media (--screen-medium) {
        order: 1;
    }
}

.report-entry__title {
    display: block;
    order: 1;
    width: 100%;
    max-width: 75rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    color: var(--color-blue--dark);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size);
    font-family: var(--font-quicksand);
    line-height: 1.2;

    @media (--screen-small) {
        font-size: var(--font-size--larger);
    }

    @media (--screen-medium) {
        order: 0;
    }
}

.report-entry__group {
    color: var(--color-blue--light);
    font-weight: var(--font-weight--medium);
    font-size: var(--font-size--tiny);
    font-family: var(--font-roboto);
    line-height: 1.6;
    text-transform: uppercase;

    @media (--screen-small) {
        font-size: var(--font-size--smaller);
    }
}

.report-entry__button {
    // If you do have the time. Don't do what I here did
    @extend .button;
    @extend .-bordered;

    display: none;
    flex: 0 1 auto;
    justify-self: flex-end;
    white-space: nowrap;
    opacity: 0;
    transition: opacity .3s ease;

    .report-overview-holder & {
        @extend .-white;

        opacity: 1;

        &:hover {
            @extend .-dark;
        }
    }

    @media (--screen-extra-large) {
        display: inline-flex;
        margin-left: 2rem;
    }
}

.report-entry__icon {
    position: relative;
    top: -.2rem;
    display: none;

    @media (--screen-medium) {
        display: inline-block;
        margin-right: .5rem;
    }
}

.report-entry__seen {
    display: none;

    .report-overview-holder & {
        position: relative;
        display: inline-block;
        overflow: hidden;

        &:hover {
            overflow: visible;

            .report-entry__seen-tooltip {
                opacity: 1;
            }
        }
    }
}

.report-entry__seen-tooltip {
    @include tooltip();
    position: absolute;
    top: calc(100% + 1rem);
    left: 50%;
    display: inline-block;
    width: auto;
    min-width: 13.8rem;
    font-size: var(--font-size--extra-small);
    text-transform: uppercase;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity .3s ease;

    &::before {
        position: absolute;
        top: -.9rem;
        left: 50%;
        width: 0;
        height: 0;
        border-color: transparent transparent var(--color-blue--light) transparent;
        border-style: solid;
        border-width: 0 .5rem .9rem;
        transform: translateX(-50%);
        content: '';
    }
}
