.gallery-item {
    position: relative;
    display: block;
    width: 100%;
    max-width: 40rem;
    margin-bottom: 2rem;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: var(--color-blue);
        opacity: 0;
        transition: opacity .3s ease;
        content: '';
        pointer-events: none;
    }

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        display: block;
        width: 3.8rem;
        height: 3.8rem;
        margin-top: -.5rem;
        background: var(--color-white);
        transform: translate3d(-50%, -50%, 0);
        opacity: 0;
        transition: opacity .3s ease, background .3s ease;
        content: '';

        pointer-events: none;
        mask-image: url('./icon-zoom.svg');
        mask-repeat: no-repeat;
        mask-size: 3.8rem 3.8rem;
    }

    &:hover,
    &:focus {
        &::before {
            opacity: 1;
        }

        &::after {
            opacity: .2;
        }
    }

    &.-count-box {
        position: relative;
        display: block;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        border: .2rem solid var(--color-gray);

        img {
            display: none;
        }

        a {
            position: relative;
            z-index: 1;
            display: block;
            width: 100%;
            padding-top: 50%;
            padding-bottom: 50%;
            color: var(--color-blue);
            font-weight: bold;
            font-size: 3.3rem;

            // font-family: var(--font-default);
            line-height: 0;
            text-align: center;
            text-decoration: none;
        }

        &::before {
            display: none;
        }

        &::after {
            height: 100%;
        }
    }

    .figure,
    a,
    img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0;
        transition: transform .3s ease;
    }
}

.gallery-item__figure {
    margin: 0;
}
