.button {
    transition:
        background-color .3s ease,
        color .3s ease;

    display: inline-block;
    margin: 0;
    padding: 1.2rem 1.8rem;

    font-family: var(--font-roboto);
    font-weight: var(--font-weight--medium);
    font-size: 1.7rem;
    line-height: 1;
    color: var(--color-white);

    text-align: center;
    text-decoration: none;

    background-color: var(--color-blue);
    border: .1rem solid transparent;
    border-radius: 3rem;
    cursor: pointer;

    &:hover,
    &:focus {
        text-decoration: none;
        background-color: var(--color-blue--dark);
    }

    &:active {
        background-color: var(--color-blue--darkest);
    }

    &.-long {
        padding: 1.2rem 4.5rem;
    }

    &.-small {
        font-size: 1.7rem;
        padding: 1.4rem 2.4rem;
        font-weight: normal;
    }

    &.-thin {
        font-size: 1.7rem;
        padding: 1.2rem 4rem;
        font-weight: normal;
    }

    &.-bordered {
        border: .1rem solid var(--color-blue);
    }

    &.-has-shadow {
        box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, .2);
    }

    &.-dark-blue {
        background-color: var(--color-blue--dark);

        &:hover,
        &:focus {
            background-color: var(--color-blue--darkest);
        }
    }

    &.-white {
        color: var(--color-blue);
        background-color: var(--color-white);

        &:hover,
        &:focus {
            color: var(--color-white);
            background-color: var(--color-blue);
        }

        &:active {
            background-color: var(--color-blue--darkest);
        }
    }

    &.-action {
        padding: .9rem 1.6rem;

        font-size: 1.4rem;
        color: var(--color-blue);
        text-transform: uppercase;
        letter-spacing: .08rem;

        background: var(--color-white);
        border: .1rem solid var(--color-blue);

        &:hover,
        &:focus {
            color: var(--color-white);
            background-color: var(--color-blue--dark);
        }

        &:active {
            background-color: var(--color-blue--darkest);
        }
    }

    &.-spaced-below {
        margin-bottom: 1rem;
    }

    @media (--screen-medium) {
        padding: 1.3rem 2.1rem;
    }

    // Dependencies
    .-header-meta & {
        margin-left: 2.1rem;
    }

    .page-link-entry & {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .cookie-consent-component__buttons & {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
