@import "~@ckeditor/ckeditor5-build-classic/build/styles.min.css";

.ck-content {
    min-height: 30vh;

    @media (--query-medium-extra-large) {
        min-height: 50rem;
    }
}

.form__textarea.-less-height + .ck .ck-content {
    min-height: 30rem;
}

.ck-content blockquote {
    // see blockquote component
}

.ck.ck-editor__editable_inline {
    padding: 2rem;

    & > :first-child,
    & > :last-child {
        margin: 0;
    }
}

.ck-balloon-panel {
    z-index: 9999 !important;
}
