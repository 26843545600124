.specialist {
    position: relative;
    z-index: 1;

    display: flex;

    width: 100%;
    max-width: 37.3rem;
    margin: 0 0 1rem 2.4rem;
    padding: 2.5rem 2.8rem;

    text-decoration: none;
    background-color: var(--color-white);

    transition: transform .3s ease, box-shadow .3s ease;
    transform: translateY(0);
    box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, .1);
    border-radius: .4rem;
    cursor: default;

    @media (--query-medium-extra-large) {
        margin: 0 0 4.8rem 4.8rem;
    }

    &.-block {
        flex-direction: column;
    }

    &.-min-height {
        min-height: 14rem;
    }

    &.-intranet {
        flex-direction: column;
        align-items: center;

        width: 100%;
        max-width: 26rem;
        min-height: 26rem;

        margin: 0 0 5rem 5rem;
        padding: 3.4rem 2rem 2.6rem;
    }

    &.-highlighted {
        background-color: var(--color-gray--light);
    }

    &.-with-hover {
        cursor: pointer;

        &:hover,
        &:focus {
            box-shadow:
                0 3rem 2.4rem 0 rgba(0, 0, 0, .05),
                0 2rem 3rem 0 rgba(0, 0, 0, .05),
                0 1rem .5rem 0 rgba(0, 0, 0, .01)
            ;
            transform: translateY(-2rem);

            .specialist__image {
                background: var(--color-gray--dark);
            }

            .specialist__state::before {
                background-color: var(--color-blue);
            }
        }
    }

    &.-with-background {
        position: relative;
        flex: 1 1 32rem;

        box-sizing: border-box;
        width: 100%;
        min-width: calc(50% - 5rem);
        //max-width: 100%;
        margin: 0 0 5rem 5rem;
        padding-top: 5.5rem;

        background-color: var(--color-gray--light) ;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-image: url(background.svg);
            background-position:  right bottom;
            background-repeat: no-repeat;
            opacity: .5;
        }

        > *:not(.specialist__header) {
            position: relative;
            z-index: 1;
        }
    }

    &.-emphasize {
        transition: transform .3s ease, box-shadow .3s ease, background-color .3s ease;
        animation-name: emphasize;
        animation-iteration-count: 4;
        animation-duration: 1s;
    }
}

.specialist__header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    display: flex;
    width: 100%;
}

.specialist__state {
    &.-hover {
        &::before,
        &::after {
            content: '';
            transition: background .3s ease;
            position: absolute;
            top: 1.3rem;
            left: 1.3rem;

            box-sizing: border-box;

            display: block;
            width: 2rem;
            height: 2rem;
            border-radius: 2rem;
            border: .2rem solid var(--color-blue);
        }

        &::before {
            z-index: 0;
            background: var(--color-white);
        }

        &::after {
            z-index: 1;
            background: url(../Images/svg/icon-checkmark.svg) center center no-repeat;
            background-size: 1.32rem .96rem;

            @supports (mask-repeat: no-repeat) {
                background: var(--color-white);
                mask-image: url(../Images/svg/icon-checkmark.svg);
                mask-repeat: no-repeat;
                mask-size: 1.32rem .96rem;
                mask-position: center center;
            }
        }
    }

    &.-edit,
    &.-lock {
        &::before {
            content: '';
            transition: background .3s ease;
            position: absolute;
            top: 1.3rem;
            right: 1.3rem;

            display: inline-block;
            width: 1.9rem;
            height: 1.9rem;

            @supports (mask-repeat: no-repeat) {
                background: var(--color-blue);
                mask-repeat: no-repeat;
            }
        }
    }

    &.-edit {
        &::before {
            background: url(../Images/svg/icon-pensil.svg) center center no-repeat;
            background-size: 1.4rem 1.5rem;

            @supports (mask-repeat: no-repeat) {
                background: var(--color-blue);
                mask-image: url(../Images/svg/icon-pensil.svg);
                mask-size: 1.4rem 1.5rem;
            }
        }
    }

    &.-lock {
        &::before {
            background: url(../Images/svg/icon-lock.svg) center center no-repeat;
            background-size: 1.4rem 1.9rem;

            @supports (mask-repeat: no-repeat) {
                background: var(--color-blue);
                mask-image: url(../Images/svg/icon-lock.svg);
                mask-size: 1.4rem 1.9rem;
            }
        }
    }
}

.specialist__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;

    .specialist.-min-height & {
        min-height: 9rem;
    }

    .-intranet & {
        align-items: center;
        margin: 0;
    }
}

.specialist__image {
    transition: background-color .3s ease;
    background: var(--color-gray);

    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    max-width: 6.1rem;
    max-height: 6.1rem;
    margin: 0 1.4rem 0 0;
    padding: 0;
    border-radius: 50%;

    &.-large {
        max-width: 100%;
        max-height: 100%;
    }

    &.-maxed-110 {
        max-width: 11rem;
        max-height: 11rem;
        margin: 0;
    }

    .specialist.-block & {
        margin-bottom: 1.4rem;
    }
}

.specialist__icon {
    transition: background .3s ease;

    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;

    background-image: url(../Images/svg/icon-checkmark-circled-green.svg); // default
    background-repeat: no-repeat;
    background-size: 1.4rem 1.4rem;
    background-position: center center;

    &.-cross {
        background-image: url(../Images/svg/icon-cross-grey.svg);
        background-size: 1rem 1rem;
    }

    &.-checkmark-gray {
        background-image: url(../Images/svg/icon-checkmark-circled-gray.svg);
    }

    &.-checkmark-green {
        background-image: url(../Images/svg/icon-checkmark-circled-green.svg);
    }
}

.specialist__figure {
    position: relative;
    display: flex;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    max-width: 12rem;
    max-height: 12rem;

    margin: 0;
    padding: .3rem;

    border: .2rem solid;
    border-radius: 100%;

    &.-partner {
        border-color: var(--color-blue);
    }

    &.-deceased {
        border-color: var(--color-gray--dark);
    }

    &.-inactive {
        border-color: var(--color-gray--dark);
    }

    &.-retired {
        border-color: var(--color-gray--dark);
    }
}

.specialist__figcaption {
    position: absolute;
    bottom: .4rem;
    left: .4rem;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.2rem;
    height: 2.2rem;
    background-color: var(--color-white);
    border: .2rem solid;
    border-radius: 50%;
    padding: 0;

    .-partner & {
        border-color: var(--color-blue);
    }

    .-deceased & {
        border-color: var(--color-gray--dark);
    }

    .-retired & {
        border-color: var(--color-gray--dark);
    }

    .-inactive {
        border-color: var(--color-gray--dark);
    }

    &:hover {
        .specialist__tooltip {
            opacity: 1;
        }
    }
}

.specialist__tooltip {
    @include tooltip();
    position: absolute;
    top: calc(100% + 1rem);
    left: 50%;
    display: inline-block;
    width: auto;
    font-size: var(--font-size--extra-small);
    text-transform: uppercase;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity .3s ease;
    pointer-events: none;

    &::before {
        position: absolute;
        top: -.9rem;
        left: 50%;
        width: 0;
        height: 0;
        border-color: transparent transparent var(--color-blue--light) transparent;
        border-style: solid;
        border-width: 0 .5rem .9rem;
        transform: translateX(-50%);
        content: '';
    }
}

.specialist__name {
    margin-bottom: .5rem;
    font-family: var(--font-roboto);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size);
    line-height: 1.18;
    color: var(--color-gray--darkest);
    text-transform: uppercase;

    .-intranet & {
        margin-top: .5rem;
        margin-bottom: 1rem;
    }
}

.specialist__title {
    display: block;
    margin-bottom: auto;
    padding-bottom: .5rem;
    color: var(--color-gray--darkest);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--smallest);
    font-family: var(--font-roboto);
    line-height: 1.15;

    .-intranet & {
        margin-bottom: 2.5rem;
    }

}

.specialist__subtitle {
    display: block;
    margin-bottom: auto;
    padding-right: 5rem;
    padding-bottom: 2rem;

    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--bold);
    font-size: 2rem;
    line-height: 1.5;
    color: var(--color-blue--dark);

    @media (--query-medium-extra-large) {
        //padding-right: 17rem;
    }
}

.specialist__location {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--color-blue--light);
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--smaller);
    font-family: var(--font-roboto);
    line-height: 1.2;
    text-transform: uppercase;

    .-intranet & {
        font-size: var(--font-size--smallest);
    }
}

.specialist__location-icon {
    margin-right: 1rem;

    .-intranet & {
        width: .8rem;
        height: 1.2rem;
        margin-right: .5rem;
    }
}


@keyframes emphasize {
    0%,
    100% {
        background-color: var(--color-white);
    }

    50% {
        background: var(--color-green--lighter);
    }
}
