.mobile-bar-public {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1.2rem;

    @media (--screen-medium) {
        display: none;
    }
}

.mobile-bar-public__logo {
    width: 4.4rem;
    height: 4.4rem;
}

.mobile-bar-public__tools {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.mobile-bar-public__hamburger {
    position: absolute;
    top: 1.6rem;
    right: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 4.4rem;
    height: 4.4rem;
    background-color: var(--color-blue--dark);
    border-radius: 50%;
    cursor: pointer;

    span {
        width: 1.8rem;
        height: .2rem;
        background-color: var(--color-white);
        border-radius: 5rem;

        &:not(:last-child) {
            margin-bottom: .3rem;
        }
    }

    @media (--query-medium-extra-large) {
        display: none;
    }

    &.-menu {
        top: 0;
        right: 0;
        background: var(--color-white);
    }
}
