.wrapper {
    display: block;
    width: 100%;

    &.-with-background-color {
        background: linear-gradient(to right, rgba(96, 168, 255, .2) 0%, rgba(48, 35, 174, .2) 100%);
        margin-bottom: 5rem;
    }

    &.-darker-background {
        background: var(--color-gray-3);
    }

    &.-underlapping {
        margin-bottom: 0;
        padding-bottom: 5rem;
    }

    &.-padded {
        padding-top: 6.6rem;
        padding-bottom: 7.2rem;
    }
}
