.tabs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 2rem;
}

.tabs__item {
    transition: color .3s ease, background-color .3s ease;
    position: relative;
    display: block;
    margin-right: auto;
    margin-bottom: 2rem;

    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--bold);
    font-size: 1.7rem;
    line-height: 1.2;
    color: var(--color-blue--dark);

    text-decoration: none;

    &::after {
        content: '';

        transition:
            background-color .3s ease,
            opacity .3s ease
        ;

        display: block;
        width: 2rem;
        height: .4rem;
        margin-top: .5rem;
        background: currentColor;
        opacity: 0;
        border-radius: .35rem;
    }

    &.-active {
        color: var(--color-pink--dark);

        &::after {
            opacity: 1;
        }
    }

    &:hover,
    &:focus {
        text-decoration: none;
        color: var(--color-black);

        &::after {
            opacity: 1;
        }
    }

    @media (--query-medium-extra-large) {
        margin-right: 7rem;
    }
}
