.specialists {
    position: relative;
    padding-bottom: 6rem;
    flex: 1;
}

.specialists__inner,
.specialists__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.specialists__collection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;

    width: calc(100% + 2.4rem);
    margin-left: -2.4rem;

    @media (--query-medium-extra-large) {
        width: calc(100% + 4.8rem);
        margin-left: -4.8rem;
    }
}

.specialists__image {
    position: absolute;
    right: 0;
    bottom: 0;
}
