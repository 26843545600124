.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.pagination__container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5rem;
    padding: .8rem 1.6rem;
    list-style-type: none;
    background-color: var(--color-white);
    border-radius: 2.5rem;
}

.pagination__item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 3.8rem;
    height: 3.8rem;
    margin: 0 .55rem;
    color: var(--color-blue);
    font-family: var(--font-roboto);
    font-weight: var(--font-weight--bold);
    font-size: 1.5rem;
    line-height: 1;

    a {
        color: var(--color-blue);
        text-decoration: none;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            z-index: 1;
            border-radius: 100%;
        }
    }

    &:not(.-spacer),
    &:not(.-prev),
    &:not(.-next) {
        &.-current,
        &:hover {
            position: relative;
            z-index: 1;
            color: var(--color-white);

            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: -1;
                width: 3.8rem;
                height: 3.8rem;
                background-color: var(--color-blue);
                border-radius: 50%;
                transform: translate3d(-50%, -50%, 0);
                content: '';
            }

            a {
                color: var(--color-white);
            }
        }
    }

    &.-prev,
    &.-next {
        position: relative;
        font: 0/0 sans-serif;

        a {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: block;
            width: 100%;
            height: 100%;
        }

        &::after {
            content: '';
            transition: background .3s ease;

            display: inline-block;
            width: 1.9rem;
            height: 1.3rem;

            background: url(../Images/svg/long-arrow.svg) center center no-repeat;
            background-size: 1.9rem 1.3rem;
            pointer-events: none;

            @supports (mask-repeat: no-repeat) {
                background: currentColor;
                mask-image: url(../Images/svg/long-arrow.svg);
                mask-repeat: no-repeat;
                mask-size: 1.9rem 1.3rem;
            }
        }
    }

    &.-prev {
        &::after {
            transform: rotate(180deg);
        }
    }
}
