.document-entry {
    @include white-box();
    @extend .-animated;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0;
    border-top: .1rem solid var(--color-gray--dark);
    transition: border-color .2s ease;

    &:last-child {
        border-bottom: .1rem solid var(--color-gray--dark);
    }

    &::before,
    &::after {
        opacity: 0;
    }

    &:hover {
        border-color: var(--color-white);
        background-color: var(--color-white);

        // WHY THE FUCK DO I NEED THIS SHIT JUST TO MAKE IT A BIT PRESENTABLE:
        &::after {
            background-color: var(--color-white);
        }

        > * {
            transform: none;
        }
    }

    @media (--query-mini-small) {
        flex-wrap: wrap;
    }
}

.document-entry__icon {
    margin-right: 5rem;

    @media screen and (max-width: 680px) {
        display: none;
    }
}

.document-entry__info {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2rem 2rem 2rem 0;

    @media (--query-mini-small) {
        width: 100%;
    }

    @media screen and (min-width: 681px) {
        max-width: calc(100% - 10.5rem);
    }
}

.document-entry__name {
    display: inline-block;
    width: 100%;
    white-space: normal;
    max-width: 100%;
    margin-bottom: 1rem;
    color: var(--color-blue);
    font-weight: var(--font-weight--medium);
    font-size: var(--font-size--small);
    font-family: var(--font-quicksand);
}

.document-entry__size {
    color: var(--color-blue--light);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--extra-small);
    font-family: var(--font-roboto);
}

.document-entry__links {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-left: auto;
    margin-right: auto;
    padding: 1rem 0;
}

.document-entry__icon-link {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 4.6rem;
    height: 4.6rem;
    margin-right: 2rem;
    text-decoration: none;
    color: var(--color-gray-4);

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        width: 4.5rem;
        height: 4.5rem;
        background-color: var(--color-white);
        border: .1rem solid var(--color-blue);
        border-radius: 50%;
        transform: translate3d(-50%, -50%, 0);
        transition: background-color .3s ease;
        content: '';
    }

    &::after {
        $w: 2.2rem;
        $h: 1.5rem;
        $icon: '../Images/svg/icon-visibility.svg';

        content: '';
        transition: background .3s ease;

        display: inline-block;
        width: $w;
        height: $h;

        background: url($icon) center center no-repeat;
        background-size: $w $h;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url($icon);
            mask-repeat: no-repeat;
            mask-size: $w $h;
        }
    }

    &:hover {
        color: var(--color-white);

        &::before {
            background-color: var(--color-blue);
        }
    }
}

.document-entry__link {
    @extend .button;
    @extend .-white;
    // I'll just continue this shit because of lack of time
    @extend .-bordered;

    &:hover {
        @extend .-dark;
    }
}

.document-entry__approval-request {
    display: inline-block;
    margin-bottom: 10px;

    &.-approved {
        color: green;
    }

    &.-disapproved {
        color: red;
    }
}
