.faq-entry {
    position: relative;
    z-index: 1;
    width: 100%;
    transition: transform .3s ease;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5rem 6rem 3rem 6rem;
    text-decoration: none;

    background-color: var(--color-white);

    &::before,
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        border-radius: .4rem;
        box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, .1);
        transition:
            transform .3s ease,
            opacity .3s ease,
            box-shadow .3s ease;
        content: '';
    }

    &::before {
        background: var(--color-white);
    }

    &::after {
        box-shadow: rgba(0, 0, 0, .1) 0 0 .5rem .2rem;
    }

    &:hover {
        transform: translateY(-1.1rem);

        &::after {
            opacity: 1;
        }

        &::before {
            box-shadow: rgba(0, 0, 0, .1) 0 0 .5rem .2rem;
        }

        &::after {
            box-shadow: rgba(0, 0, 0, .1) 0 1rem .5rem .2rem;
        }
    }

    &:not(:last-child) {
        margin-bottom: 1.8rem;
    }

    @media (--query-mini-medium) {
        padding: 2rem;
        flex-wrap: wrap;
    }

    > * {
        transition: transform .3s ease;
    }
}

.faq-entry__info {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
}

.faq-entry__title {
    display: block;
    margin-bottom: 1rem;
    color: var(--color-blue);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--larger);
    font-family: var(--font-roboto);
}

.faq-entry__description {
    display: block;
    margin: 0 0 2rem 0;
    max-width: 67rem;
    color: var(--color-blue--light);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--smaller);
    font-family: var(--font-roboto);
}

.faq-entry__button {
    // yea I know.. don't do this
    @extend .button;
    flex: 0;
    white-space: nowrap;
}
