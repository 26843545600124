.agenda-entry--large {
    @include white-box();
    @extend .-animated;
    padding: 1.8rem 4.1rem;
    text-decoration: none;

    &:not(:last-child) {
        margin-bottom: 1.6rem;
    }

    &:hover {
        z-index: 2;

        .agenda-entry--large__attachement-image {
            rect {
                fill: var(--color-white);
            }
        }
    }

    .-page-intranet-agenda-detail & {
        transform: translateY(50%);
    }
}

.agenda-entry--large__inner {
    display: flex;
    flex-direction: column;

    @media (--screen-medium) {
        flex-direction: row;
    }
}

.agenda-entry--large__planned {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;

    @media (--query-mini-small) {
        margin-bottom: 1rem;
    }

    @media (--screen-medium) {
        margin-right: 6.6rem;
    }
}

.agenda-entry--large__calendar-image {
    width: 1.4rem;
    height: 1.8rem;
    margin-right: 1rem;

    @media (--screen-medium) {
        width: 3.4rem;
        height: 3.8rem;
        margin-right: 1.6rem;
    }
}

.agenda-entry--large__planned-content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    align-items: flex-start;

    @media (--screen-medium) {
        flex-direction: column;
        margin-right: 6.6rem;
    }
}

.agenda-entry--large__time {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.6rem;

    font-family: var(--font-roboto);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--extra-small);
    line-height: 1.5;
    color: var(--color-blue--light);

    &::before {
        content: '';
        position: relative;
        top: -.1rem;
        transition: background .3s ease;

        display: inline-block;
        vertical-align: middle;
        width: 1.8rem;
        height: 1.8rem;

        background: url(../Images/svg/icon-clock.svg) center center no-repeat;
        background-size: 1.8rem 1.8rem;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url(../Images/svg/icon-clock.svg);
            mask-repeat: no-repeat;
            mask-size: 1.8rem 1.8rem;
        }
    }
}

.agenda-entry--large__date {
    color: var(--color-gray--darkest);
    font-weight: var(--font-weight--bold);
    font-family: var(--font-roboto);
    line-height: 1.2;

    font-size: 1.2rem;

    @media (--screen-medium) {
        font-size: var(--font-size--small);
    }
}

.agenda-entry--large__date-label {
    margin-right: .5rem;
    color: var(--color-blue--light);
    font-weight: var(--font-weight--bold);
    font-family: var(--font-roboto);
    line-height: 1.2;
    text-transform: uppercase;

    font-size: 1.2rem;

    @media (--screen-medium) {

    }
}

.agenda-entry--large__title {
    display: block;
    width: 100%;
    margin: 0 0 1rem 0;
    font-family: var(--font-quicksand);
    font-weight: var(--font-weight--medium);
    font-size: var(--font-size--larger);
    line-height: 1.2;
    color: var(--color-blue);
    text-decoration: none;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
    }

    @media (--screen-medium) {
        font-size: var(--font-size--largest);
    }
}

.agenda-entry--large__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .tag {
        margin-right: 1.6rem;
    }
}

.agenda-entry--large__attachments {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1rem;
}

.agenda-entry--large__attachement-image {
    width: 3rem;
    height: 3rem;

    &:not(:last-child) {
        margin-right: 1rem;
    }

    @media (--screen-medium) {
        width: 5.5rem;
        height: 5.5rem;

        :not(:last-child) {
            margin-right: 1.6rem;
        }
    }
}

.agenda-entry--large__tag {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 1.5rem;
    font-family: var(--font-roboto);
    font-weight: var(--font-weight--bold);
    font-size: 1.5rem;
    line-height: 1.2;
    color: var(--color-green);

    &::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: .8rem;
        height: .8rem;
        margin-right: .5rem;
        border-radius: 100%;
        background: var(--color-green);
    }

}
