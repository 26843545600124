.mobile-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1.2rem;

    @media (--screen-medium) {
        display: none;
    }
}

.mobile-bar__logo {
    width: 4.4rem;
    height: 4.4rem;
}

.mobile-bar__tools {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.mobile-bar__user {
    display: inline-flex;
    align-items: center;
    border-radius: 2.2rem;
    margin-right: 1.6rem;
    padding-right: .5rem;
    background: var(--color-white);
    color: var(--color-blue);

    &::after {
        $w: 1.3rem;
        $h: .8rem;
        $icon: 'arrow.svg';

        content: '';
        transition: background .3s ease;

        display: inline-block;
        width: $w;
        height: $h;
        margin: 0 .5rem;

        background: url($icon) center center no-repeat;
        background-size: $w $h;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url($icon);
            mask-repeat: no-repeat;
            mask-size: $w $h;
        }
    }
}

.mobile-bar__user-icon {
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 100%;
}

.mobile-bar__search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 4.4rem;
    height: 4.4rem;
    margin-right: 1.6rem;
    background-color: var(--color-white);
    border-radius: 50%;
}

.mobile-bar__hamburger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 4.4rem;
    height: 4.4rem;
    background-color: var(--color-blue--dark);
    border-radius: 50%;

    span {
        width: 1.8rem;
        height: .2rem;
        background-color: var(--color-white);
        border-radius: 5rem;

        &:not(:last-child) {
            margin-bottom: .3rem;
        }
    }
}
