@import '~photoswipe/src/css/main';
@import '~photoswipe/src/css/default-skin/default-skin';

.gallery-items {
    display: block;
    width: 100%;

    margin-bottom: 5rem;
    text-align: center;
    column-count: 2;
    column-gap: 2rem;

    @media (--screen-medium) {
        margin-bottom: 9rem;
        column-count: 3;
    }
}
