.error {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: auto;
    padding: 2rem;
    background-color: var(--color-alert--error);
    transform: translateY(100%);
    transition: transform .3s ease;

    &.-active {
        transform: translateY(calc(100% - 60px));

        &:hover {
            transform: translateY(0);
        }
    }
}

.error__title {
    color: var(--color-white);
}

.error__text {
    color: var(--color-white);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--large);
    font-family: var(--font-roboto);
}

.error__message {
    position: relative;
    color: var(--color-white);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--medium);
    font-family: var(--font-roboto);
    line-height: 1.4;

    &::before {
        position: absolute;
        top: 0;
        left: -2rem;
        width: .3rem;
        height: 100%;
        background-color: var(--color-white);
        content: '';
    }
}
